import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { produce } from "immer"
import { useContext } from "react"
import { useRecoilCallback } from "recoil"
import { atomConversationsRecord } from "../Atom"
import {
	UserHistoryConversationsById, ConversationStateSyncStatus, DebugById,
} from "../Types"
import {
	Conversation, Debug, MessagesById,
	OutputsById, UserHistoryConversations, FrontendStateVersionTypes,
} from "deblank-api-types"
import { defaultOutputsIdCounts } from "../utils/defaultOutputsIdCounts"

const useSetShowNewChat = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	return useRecoilCallback(({ set, }) => (params: {
		showNewChatView: boolean,
	}) => {

		if (params.showNewChatView) {
			trackConversationEvent({
				eventName: "NewChat",
			})
		}

		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.showNewChatView = params.showNewChatView
		}))
	})
}

const useAddNewConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		userMessage: string,
		conversationId: string,
		createDate: string,
		currentIntroQuestion: number | null,
		attachedGuideline?: {
			fileName: string,
		},
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {

			const userHistoryConversation: UserHistoryConversationsById = {
				[params.conversationId]: {
					id: params.conversationId,
					createdAt: params.createDate,
					updatedAt: params.createDate,
					metadata: {
						conversationName: params.userMessage,
						outputs: [],
						messages: [],
					},
				},
			}

			draft.conversations = {
				...draft.conversations,
				[params.conversationId]: {
					stateVersion: FrontendStateVersionTypes.Current,
					id: params.conversationId,
					creationDate: params.createDate,
					outputs: {},
					messages: {},
					pinnedItems: [],
					pendingMessage: null,
					pendingNotifications: null,
					outputsIdCounts: defaultOutputsIdCounts,
					colorsPlaygroundIdRef: null,
					fontPlaygroundIdRef: null,
					imageModalIdRef: null,
					currentIntroQuestion: params.currentIntroQuestion,
					attachedGuideline: params.attachedGuideline || null,
				},
			}

			draft.userHistoryConversations
				? draft.userHistoryConversations = {
					...userHistoryConversation,
					...draft.userHistoryConversations,
				}
				: draft.userHistoryConversations = userHistoryConversation
			draft.activeConversationId = params.conversationId
			draft.showNewChatView = false
		}))
	})
}

const useClearPendingNotificationsInActiveConversation = () => {
	return useRecoilCallback(({ set, }) => () => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const activeConversation = draft.conversations[draft.activeConversationId!]
			activeConversation.pendingNotifications = null
		}))
	})

}

const useUpdateCurrentConversationId = () => {
	return useRecoilCallback(({ set, }) => (params: {
		newConversationId: string,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.showNewChatView = false
			draft.activeConversationId = params.newConversationId
			const existConversation = draft.conversations[params.newConversationId]

			if (!existConversation) {
				const metadata = draft.userHistoryConversations
					? draft.userHistoryConversations[params.newConversationId].metadata
					: null

				if (metadata) {
					let messagesToDictionary: MessagesById = {}
					let outputsToDictionary: OutputsById = {}

					metadata.outputs.forEach(output => {
						outputsToDictionary = {
							...outputsToDictionary,
							[output.id]: output,
						}
					})

					metadata.messages.forEach(message => {
						messagesToDictionary = {
							...messagesToDictionary,
							[message.id]: message,
						}
					})

					const conversation = {
						[params.newConversationId]: {
							stateVersion: FrontendStateVersionTypes.Current,
							id: params.newConversationId,
							creationDate: "",
							outputs: outputsToDictionary,
							outputsIdCounts: defaultOutputsIdCounts,
							pinnedItems: [],
							messages: messagesToDictionary,
							pendingMessage: null,
							pendingNotifications: null,
							fontPlaygroundIdRef: null,
							colorsPlaygroundIdRef: null,
							imageModalIdRef: null,
							currentIntroQuestion: 0,
							attachedGuideline: null,
						},
					}
					draft.conversations = {
						...draft.conversations,
						...conversation,
					}
				}

				draft.loadActiveConversation = true
			}
		}))
	})
}

const useUpdateUserConversationHistory = () => {
	return useRecoilCallback(({ set, }) => (params: {
		conversations: ({ debug: Debug | null, } & UserHistoryConversations)[],
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			let conversationList: UserHistoryConversationsById = {}
			let debugList: DebugById = {}

			params.conversations.forEach(conversation => {
				conversationList = {
					...conversationList,
					[conversation.id]: {
						id: conversation.id,
						createdAt: conversation.createdAt,
						updatedAt: conversation.updatedAt,
						metadata: conversation.metadata,
					},
				}
				if (conversation.debug) {
					debugList = {
						...debugList,
						[conversation.id]: conversation.debug,
					}
				}
			})

			draft.userHistoryConversations = conversationList
			draft.debugConversations = debugList
		}))
	})
}

const useSetConversationById = () => {
	return useRecoilCallback(({ set, }) => (params: {
		conversation: Conversation,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const existConversation = draft.conversations[params.conversation.id]
			if (existConversation) {
				draft.conversations[params.conversation.id] = params.conversation
			} else {
				draft.conversations = {
					...draft.conversations,
					[params.conversation.id]: params.conversation,
				}
			}
			draft.showNewChatView = false
		}))
	})
}

const useSetConversationSaveState = () => {
	return useRecoilCallback(({ set, }) => (
		state: ConversationStateSyncStatus,
	) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.conversationStateSyncStatus = state
		}))
	})
}

const useSetConversationAttachment = () => {
	return useRecoilCallback(({ set, }) => (params: {
		conversationId: string,
		fileName?: string,
		originalAmountOfCharacters?: number,
	}
	) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			if (params.fileName) {
				draft.conversations[params.conversationId].attachedGuideline = {
					...draft.conversations[params.conversationId].attachedGuideline,
					fileName: params.fileName,
				}
			}

			if (params.originalAmountOfCharacters) {
				draft.conversations[params.conversationId].attachedGuideline = {
					fileName: draft.conversations[params.conversationId].attachedGuideline?.fileName || "",
					originalAmountOfCharacters: params.originalAmountOfCharacters,
				}
			}


		}))
	})
}

const useUpdateConversationModifiedAt = () => {
	return useRecoilCallback(({ set, }) => (params: {
		conversationId: string,
		updatedAt: string,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const conversationList = draft.userHistoryConversations
				? draft.userHistoryConversations[params.conversationId]
				: null

			if (conversationList) {
				conversationList.updatedAt = params.updatedAt
			}
		}))
	})
}

const useDeleteConversationById = () => {
	return useRecoilCallback(({ set, }) => (params: {
		conversationId: string,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			if (draft.activeConversationId === params.conversationId) {
				draft.showNewChatView = true
			}
			delete draft.conversations[params.conversationId]
			delete draft.userHistoryConversations![params.conversationId]
		}))
	})
}

const useUpdateConversationNameById = () => {
	return useRecoilCallback(({ set, }) => (params: {
		conversationId: string,
		newName: string,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const conversationList = draft.userHistoryConversations
				? draft.userHistoryConversations[params.conversationId]
				: null

			if (conversationList) {
				conversationList.metadata.conversationName = params.newName
			}
		}))
	})
}

const useSetLoadConversationState = () => {
	return useRecoilCallback(({ set, }) => (state: boolean) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.loadActiveConversation = state
		}))
	})
}

const useClearCurrentConversationId = () => {
	return useRecoilCallback(({ set, }) => () => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.activeConversationId = null
		}))
	})
}

const useSetIsSavingNewConversationName = () => {
	return useRecoilCallback(({ set, }) => (state: boolean) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			draft.isSavingNewConversationName = state
		}))
	})
}

export const settersConversations = {
	useAddNewConversation,
	useClearPendingNotificationsInActiveConversation,
	useSetShowNewChat,
	useSetConversationAttachment,
	useUpdateUserConversationHistory,
	useUpdateCurrentConversationId,
	useSetConversationById,
	useSetConversationSaveState,
	useUpdateConversationModifiedAt,
	useDeleteConversationById,
	useUpdateConversationNameById,
	useSetLoadConversationState,
	useClearCurrentConversationId,
	useSetIsSavingNewConversationName,
}
