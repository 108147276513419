// @ts-expect-error - types declaration missing
import ColorThief from "colorthief"
import chroma from "chroma-js"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import {
	ColorsRelatedActions, DisplayWidgetType, ImageResponse, ImagesRelatedActions, SearchResponse, UserMessageTypes,
} from "deblank-api-types"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { getRandomItemFromArray } from "@/utils/arrayUtils"
import ImageManager from "@/utils/ImageManager"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = ImageSelectValue

type Image_Related_1_State = OneStepAction<Step1>

const createPalette = async (params: {
	amountOfColorPalette: number,
	imgSrc: string | null,
}) => {
	if (!params.imgSrc) { return null }
	const colorThief = new ColorThief()
	const img = new Image()

	img.crossOrigin = "Anonymous"
	img.src = params.imgSrc!

	await new Promise((resolve, reject) => {
		img.onload = resolve
		img.onerror = reject
	})

	if (img.complete && img.naturalHeight !== 0) {
		try {
			const colorResult: string[] = []
			const rgbColors = colorThief.getPalette(img, params.amountOfColorPalette, 1)

			rgbColors.forEach((color: number[]) => {
				const hexColor = chroma.rgb(color[0], color[1], color[2]).hex()
				colorResult.push(hexColor)
			})
			return (colorResult)
		} catch (error) {
			return (null)
		}
	} else {
		return (null)
	}
}

export const Image_Related_Action_Config_1
	= ((): RelatedActionData<Image_Related_1_State> => {

		const handleProcessAction = (context: RelatedActionContext<Image_Related_1_State>) =>
			async () => {
				const widgetSelected = context.currentValue.step1

				const getImageSrc = async () => {
					const type = widgetSelected.widgetType

					switch (type) {
						case DisplayWidgetType.Images: {
							const widgetDetails = widgetSelected.widgetItemDetails as ImageResponse
							const getSignedUrl = await ImageManager.getImageUrl({
								imageFileName: widgetDetails.imageFileName,
								conversationId: context.conversationId,
							})
							return (getSignedUrl.signedUrl)
						}
						case DisplayWidgetType.Searches: {
							const widgetDetails = widgetSelected.widgetItemDetails as SearchResponse
							return (widgetDetails.imageUrl)

						}
						case DisplayWidgetType.MockupFonts:
						case DisplayWidgetType.MockupColors:
						case DisplayWidgetType.Text:
						case DisplayWidgetType.Colors:
						case DisplayWidgetType.Fonts:
						case DisplayWidgetType.FontsExplanations:
						case DisplayWidgetType.ColorsExplanations:
						case DisplayWidgetType.AccessibilityColors:
						case DisplayWidgetType.Brands:
						case DisplayWidgetType.Attachment:
						case DisplayWidgetType.Question: {
							return (
								null
							)
						}
						default: {
							const _exhaustiveCheck: never = type
							const errorMessage = `Unknown widget type in related extract color: ${_exhaustiveCheck}`
							throw new Error(errorMessage)
						}
					}
				}

				const palette = await createPalette({
					amountOfColorPalette: 5,
					imgSrc: await getImageSrc(),
				})


				if (palette) {
					context.onAddCreateNotifications({
						notifications: [
							{
								relatedActions: [
									getRandomItemFromArray([
										ColorsRelatedActions.HowToApplyColorsInUIDesign,
										ColorsRelatedActions.CheckColorPaletteAccessibility,
									]),
									ColorsRelatedActions.SeeColorPaletteGradients,
									getRandomItemFromArray([
										ColorsRelatedActions.GenerateImagesWithColorPalette,
										ColorsRelatedActions.SearchForImagesWithColorPalette,
									]),
									getRandomItemFromArray([
										ColorsRelatedActions.ExpandSaturationLevels,
										ColorsRelatedActions.ExpandColorPaletteHues,
										ColorsRelatedActions.SeeColorPaletteGradients,
										ColorsRelatedActions.ModifyColorPaletteLightness,
									]),
								],
								output: {
									type: DisplayWidgetType.Colors,
									metadata: null,
									data: {
										isPaginated: true,
										pages: [{
											results: [{
												palette: palette,
											},],
											pageNumber: 0,
										},],
									},
								},
								generatedByWidget: context.idRef,
								userMessage: {
									type: UserMessageTypes.relatedAction,
									informationBlocks: [{
										title: "Extract color palette",
									},],
									message: "Extract color palette from this image",
									idRefs: [widgetSelected.idRef,],
								},
								// eslint-disable-next-line max-len
								internalMessageForAgent: `The user extracted the colors from the image with ID "${widgetSelected.idRef.widgetItemId}"`,
							},
						],
					})
				}
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<Image_Related_1_State>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<Image_Related_1_State>) =>
				(data: ImageSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<Image_Related_1_State>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		return {
			id: ImagesRelatedActions.ExtractColorPalette,
			label: "Extract color palette",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.images,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select an image",
						componentType: ConfigStepType.select,
						selectType: SelectType.images,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
				],
			},
		}

	})()
