import { selector } from "recoil"
import { atomUITemporary } from "./Atom"
import { produce } from "immer"
import { UserPrompt } from "./Types"

const prefixRecoilKey = (key: string) => `UI_Temporary_${key}`

const isSidebarOpen = selector<boolean>({
	key: prefixRecoilKey("isSidebarOpen"),
	get: ({ get, }) => {
		return get(atomUITemporary).sidebarIsOpen
	},
})

const isPinnedListOpen = selector<boolean>({
	key: prefixRecoilKey("isPinnedListOpen"),
	get: ({ get, }) => {
		return get(atomUITemporary).pinnedIsOpen
	},
})

const isDebugPanelOpen = selector<boolean>({
	key: prefixRecoilKey("isDebugPanelOpen"),
	get: ({ get, }) => {
		return get(atomUITemporary).debugPanelIsOpen
	},
})

const userPromptState = selector<string>({
	key: prefixRecoilKey("fontTitleCardPreview"),
	get: ({ get, }) => {
		const userState = get(atomUITemporary)
		return userState.userPrompt.message
	},
	set: ({ set, }, newValue) => {
		const prompt = newValue as string
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.userPrompt.message = prompt
		}))
	},
})

const userPromptOutputIdSelected = selector<UserPrompt["outputIdSelected"]>({
	key: prefixRecoilKey("userPromptOutputIdSelected"),
	get: ({ get, }) => {
		const userState = get(atomUITemporary)
		return (userState.userPrompt.outputIdSelected)
	},
})

const isInstagramBannerOpen = selector<boolean>({
	key: prefixRecoilKey("isInstagramBannerOpen"),
	get: ({ get, }) => {
		return get(atomUITemporary).instagramBannerIsOpen
	},
})

const userFileAttachment = selector<File | null>({
	key: prefixRecoilKey("userFileAttachment"),
	get: ({ get, }) => {
		const userState = get(atomUITemporary)
		return userState.userPrompt.fileAttachment
	},
	set: ({ set, }, newValue) => {
		const fileAttachment = newValue as File | null
		set(atomUITemporary, previous => produce(previous, draft => {
			draft.userPrompt.fileAttachment = fileAttachment
		}))
	},
})

export const selectorsUITemporary = {
	isSidebarOpen,
	isPinnedListOpen,
	isDebugPanelOpen,
	userPromptState,
	userPromptOutputIdSelected,
	isInstagramBannerOpen,
	userFileAttachment,
}
