import React, { useContext } from "react"
import Styles from "./PlaygroundToolbar.module.scss"
import { FontFromCache } from "@/recoil/FontCache/Types"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { PlaygroundMode } from "../FontsPlaygroundMobile"
import FontSizeToolbar from "./FontSizeToolbar/FontSizeToolbar"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import ArrowLeftIcon from "deblank-common/src/assets/images/icon-library/arrow-left.svg"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import { TextState } from "../../FontsPlaygroundHelper"
import FontWeightToolbar from "./FontWeightToolbar/FontWeightToolbar"
import TextAlignToolbar from "./TextAlignToolbar/TextAlignToolbar"
import IconTextLeft from "deblank-common/src/assets/images/icon-library/text-left.svg"
import IconFontWeight from "deblank-common/src/assets/images/icon-library/weight.svg"
import IconFontSize from "deblank-common/src/assets/images/icon-library/size.svg"
import IconEditText from "deblank-common/src/assets/images/icon-library/edit.svg"
import { m } from "framer-motion"
import { playgroundMotionProps } from "./Animations"


type Props = {
	onEditSize: () => void,
	onEditFontWeight: () => void,
	onEditAlign: () => void,
	onEditText: () => void,
	onCloseMode: () => void,
	onClosePlayground: () => void,
	onChangeTextState: React.Dispatch<React.SetStateAction<TextState>>,
	textState: TextState,
	font: FontFromCache,
	messageId: string,
	selectedPlaygroundMode: PlaygroundMode | null,
}

const PlaygroundToolbar = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const renderToolbarContent = () => {
		switch (props.selectedPlaygroundMode) {
			case PlaygroundMode.FontWeight:
				return <FontWeightToolbar
					font={props.font}
					onChange={props.onChangeTextState}
					state={props.textState}
				/>
			case PlaygroundMode.FontSize:
				return <FontSizeToolbar
					font={props.font}
					onChange={props.onChangeTextState}
					state={props.textState}
				/>
			case PlaygroundMode.Align:
				return <TextAlignToolbar
					font={props.font}
					onChange={props.onChangeTextState}
					state={props.textState}
				/>
			default:
				return <div className={Styles.row}>
					<button className={Styles.action} onClick={props.onEditSize}>
						<IconFontSize />
						Size
					</button>
					<button className={Styles.action} onClick={props.onEditFontWeight}>
						<IconFontWeight />
						Weight
					</button>
					<button className={Styles.action} onClick={props.onEditAlign}>
						<IconTextLeft />
						Align
					</button>
					<button className={Styles.action} onClick={(e) => {
						e.stopPropagation()
						props.onEditText()
					}}>
						<IconEditText />
						Edit text
					</button>
				</div>
		}
	}

	const renderToolbarHeader = () => {
		switch (props.selectedPlaygroundMode) {
			case PlaygroundMode.FontWeight:
				return <div className={Styles.row}>
					<p className={Styles.title}>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={props.onCloseMode}
							iconSVGComponent={ArrowLeftIcon}
						/>
						Font Weight
					</p>
				</div>
			case PlaygroundMode.FontSize:
				return <div className={Styles.row}>
					<p className={Styles.title}>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={props.onCloseMode}
							iconSVGComponent={ArrowLeftIcon}
						/>
						Font size
					</p>
				</div>
			case PlaygroundMode.Align:
				return <div className={Styles.row}>
					<p className={Styles.title}>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={props.onCloseMode}
							iconSVGComponent={ArrowLeftIcon}
						/>
						Font Align
					</p>
				</div>
			default:
				return <div className={Styles.row}>
					<p className={Styles.title}>
						{props.font.metadata.name}
						<a
							className={Styles.link_vendor}
							href={props.font.metadata.linkToVendor}
							target="_blank"
							rel="noreferrer"
							onClick={async () => {
								await trackConversationEvent({
									eventName: "ClickGetFont",
									fontName: props.font.metadata.name,
									responseId: props.messageId,
								})
							}}
						>
							<LinkIcon />
						</a>
					</p>
					<ButtonIcon
						customStyles={{ variant: "ghost", }}
						type="button"
						onClick={props.onClosePlayground}
						iconSVGComponent={CrossIcon}
					/>
				</div>
		}
	}

	return (
		<m.div {...playgroundMotionProps} className={Styles.wrapper}>
			{renderToolbarHeader()}
			<span className={Styles.divider} />
			{renderToolbarContent()}
		</m.div>
	)
}

export default PlaygroundToolbar