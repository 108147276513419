/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useContext, useEffect, useRef, useState } from "react"
import Styles from "./WidgetConversationAttachment.module.scss"
import Markdown from "marked-react"
import ChevronIcon from "deblank-common/src/assets/images/icon-library/chevron-bottom.svg"
import { AttachmentResponse, EntryAttachmentResult, IdRefWidget } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"

type Props = {
	idRef: IdRefWidget,
	widgetDetails: EntryAttachmentResult,
}

const WidgetConversationAttachment = (props: Props) => {
	const detailsRefs = useRef<(HTMLDetailsElement | null)[]>([])
	const widgetData = props.widgetDetails.data.results[0]
	const currentConversation = useRecoilValue(selectorsConversations.currentConversation)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [isFirstRender, setIsFirstRender,] = useState(true)

	useEffect(() => {
		if (!currentConversation?.attachedGuideline?.originalAmountOfCharacters && isFirstRender) {
			trackConversationEvent({
				eventName: "UploadGuidelineEvent",
				originalAmountOfCharacters: widgetData.originalAmountOfCharacters,
				fileName: widgetData.fileName,
			})
		}
		setIsFirstRender(false)
	}, [])

	const closeOpenedDetails = (index: number) => {
		detailsRefs.current.forEach((detail, i) => {
			if (i !== index && detail) {
				detail.removeAttribute("open")
			}
		})
	}

	const keyToTitle = {
		"missionAndPurpose": "Mission and Purpose",
		"values": "Values",
		"targetAudience": "Target Audience",
		"perception": "Perception",
		"personality": "Personality",
		"visualIdentity": "Visual Identity",
	}

	return (
		<section className={Styles.attachment_wrapper}>
			<div className={Styles.attachment_main}>
				<h3>{widgetData.main.brand}</h3>
				<p>{widgetData.main.summary}</p>

				{widgetData.main.colors.length > 0 && <div className={Styles.palette}>
					{widgetData.main.colors.map((color, index) => (
						<div key={index}
							className={Styles.color_item}
							style={{ "--current_color": color, } as React.CSSProperties}
						/>
					))}
				</div>}
			</div>
			<div className={Styles.details_wrapper}>
				{Object.keys(widgetData.details).map((k, index) => {
					const key = k as keyof AttachmentResponse["details"]

					return widgetData.details[key] && <details
						key={index}
						className={Styles.attachment_block}
						ref={(el) => (detailsRefs.current[index] = el)}
					>
						<summary
							className={Styles.details_title}
							onClick={() => closeOpenedDetails(index)}
						>
							<span>{keyToTitle[key]}</span>
							<span className={Styles.icon_container}>
								<ChevronIcon />
							</span>
						</summary>
						<div className={Styles.details_content}>
							<Markdown>{widgetData.details[key]}</Markdown>
						</div>
					</details>
				})}
			</div>
		</section>

	)
}

export default WidgetConversationAttachment
