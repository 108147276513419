import React from "react"
import Styles from "./Tooltip.module.scss"
import classNames from "classnames"


export type CustomTooltipStyles = {
	position?: "left" | "right" | "top" | "bottom",
	showAlways?: boolean,
	animationDelay?: "short" | "long",
	fullWidth?: boolean,
	containerNotClickable?: boolean,
}

type Props = {
	children: React.ReactNode,
	tooltipContent: React.ReactNode,
	customStyles?: CustomTooltipStyles,
	disabled?: boolean,
}

const Tooltip = (props: Props) => {

	const wrapperClasses = classNames(
		Styles.wrapper,
		{
			[Styles.has_short_delay_on_hover]: props.customStyles?.animationDelay === "short",
			[Styles.has_long_delay_on_hover]: props.customStyles?.animationDelay === "long",
			[Styles.full_width]: props.customStyles?.fullWidth,
			[Styles.diabled_container_click]: props.customStyles?.containerNotClickable,
		}
	)

	const tooltipClasses = classNames(
		Styles.tooltip,
		Styles.content,
		{
			[Styles.tooltip_position_top]: (props.customStyles?.position || "top") === "top",
			[Styles.tooltip_position_bottom]: props.customStyles?.position === "bottom",
			[Styles.tooltip_position_left]: props.customStyles?.position === "left",
			[Styles.tooltip_position_right]: props.customStyles?.position === "right",
		}
	)


	return (
		<div className={wrapperClasses}>
			{props.children}

			{!props.disabled
				&& <div className={tooltipClasses}>
					{props.tooltipContent}
				</div>
			}
		</div>
	)
}

export default Tooltip
