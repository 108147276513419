/* eslint-disable max-len */
import React from "react"
import Styles from "./InstagramFooter.module.scss"
import InstagramLogo from "deblank-common/src/assets/images/social-media-icons/instagram.svg"
import CloseIcon from "deblank-common/src/assets/images/icons/cross.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { m } from "framer-motion"
import { footerMotionProps } from "./Animations"
import { useTestHelper } from "@/hooks/useTestHelper"

type Props = {
	disableCloseButton?: boolean,
}

const InstagramFooter = (props: Props) => {
	const showInstagramBanner = settersUiTemporary.useShowInstagramBanner()
	const testHelper = useTestHelper()

	const handleCloseBanner = () => {
		showInstagramBanner({ showBanner: false, })
	}

	return (
		<m.footer
			className={Styles.footer}
			{...footerMotionProps}
			{...testHelper?.hideOnScreenshot}
		>
			<div className={Styles.gradient_border} />
			<div className={Styles.content}>
				<InstagramLogo className={Styles.logo} />
				<p className={Styles.message}>
					Follow our private Instagram <a href="https://instagram.com/vip_deblank" target="_blank" className={Styles.link} rel="noreferrer">@vip_deblank</a> for updates exclusively for Alpha testers!
				</p>
			</div>

			{!props.disableCloseButton && <button onClick={handleCloseBanner} className={Styles.close_button}>
				<CloseIcon />
			</button>}
		</m.footer>
	)
}

export default InstagramFooter
