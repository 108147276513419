import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { MockupsRelatedActions, UserMessageTypes } from "deblank-api-types"
import { MockupsSelectValue } from "@/components/WidgetsByType/Mockups/WidgetMockupsTypes"
import { valueIsEmpty } from "../utils/RelatedUtils"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"

type Step1 = MockupsSelectValue

type Mockup_Related_1_State = OneStepAction<Step1>

export const Mockups_Related_Action_Config_1
	= ((): RelatedActionData<Mockup_Related_1_State> => {

		const handleProcessAction = (context: RelatedActionContext<Mockup_Related_1_State>) =>
			// eslint-disable-next-line require-await
			async (data: Mockup_Related_1_State) => {

				await context.onAddPendingMessage({
					informationBlocks: [{
						title: "Upscale mockup",
					},],
					type: UserMessageTypes.relatedAction,
					message: "Upscale the selected image.",
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					prompt: "With your images agent use your Upscale tool with the selected image.",
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<Mockup_Related_1_State>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<Mockup_Related_1_State>) =>
				(data: MockupsSelectValue) => {
					context.onSetState(prev => ({
						...prev,
						step1: data,
					}))
				},
			isEmpty: (context: RelatedActionContext<Mockup_Related_1_State>) => {
				return valueIsEmpty(context.currentValue.step1)
			},
		}

		return {
			id: MockupsRelatedActions.UpscaleMockup,
			label: "Upscale mockup",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.mockups,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select mockups to upscale",
						componentType: ConfigStepType.select,
						selectType: SelectType.mockups,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
				],
			},
		}

	})()
