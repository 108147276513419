import React, { useContext } from "react"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { TextState } from "../../../FontsPlaygroundHelper"
import { FontFromCache } from "@/recoil/FontCache/Types"
import InputRange from "@/components/InputRange/InputRange"


type Props = {
	state: TextState,
	font: FontFromCache,
	onChange: React.Dispatch<React.SetStateAction<TextState>>,
}

const FontSizeToolbar = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const handleOnChangeFontSize = async (value: number) => {
		await trackConversationEvent({
			eventName: "ChangeFontSize",
			size: value,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				fontSize: {
					...prev.fontSize,
					value: value,
				},
			}
		})
	}

	return (
		<InputRange
			value={props.state.fontSize.value}
			labelValueUnit="px"
			min={props.state.fontSize.min || 10}
			max={props.state.fontSize.max}
			onChange={handleOnChangeFontSize}
			styles={{
				color: "primary",
				size: "large",
				fullWidth: true,
			}}
		/>
	)
}

export default FontSizeToolbar