import React from "react"
import { WidgetColors } from "@/components/WidgetsByType/Colors/WidgetColors"
import { WidgetFonts } from "@/components/WidgetsByType/Fonts/WidgetFonts"
import { WidgetImages } from "@/components/WidgetsByType/Images/WidgetImages"
import { WidgetMockups } from "@/components/WidgetsByType/Mockups/WidgetMockups"
import { IdRefWidgetWithPaginationItem, DisplayWidgetType } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import * as Sentry from "@sentry/react"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"

const BubbleUserWidgetSelector = (props: {
	idRef: IdRefWidgetWithPaginationItem,
}) => {

	const output = useRecoilValue(selectorsWidgets.outputById(props.idRef.widgetId))
	const type = output.type

	if (!output) {
		const errorMessage = `Output not found in Bubble User: ${props.idRef.widgetId}`
		Sentry.captureMessage(errorMessage)
		throw new Error(errorMessage)
	}

	const renderSelectedOutput = () => {
		switch (type) {
			case DisplayWidgetType.Colors:
				return (
					<WidgetColors.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						widgetItemDetails={
							output.data
								.pages[props.idRef.widgetPageIndex]
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.AccessibilityColors:
				return (
					<WidgetColors.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						widgetItemDetails={
							output.data
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.ColorsExplanations:
				return (
					<WidgetColors.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						widgetItemDetails={
							output.data
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.Fonts:
				return (
					<WidgetFonts.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						metadata={output.metadata}
						widgetItemDetails={
							output.data
								.pages[props.idRef.widgetPageIndex]
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.FontsExplanations:
				return (
					<WidgetFonts.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						metadata={output.metadata}
						widgetItemDetails={
							output.data
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.Images:
				return (
					<WidgetImages.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						widgetItemDetails={
							output.data
								.pages[props.idRef.widgetPageIndex]
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.MockupColors:
			case DisplayWidgetType.MockupFonts:
				return (
					<WidgetMockups.BubbleUserMesaage
						idRef={props.idRef}
						widgetType={output.type}
						widgetItemDetails={
							output.data
								.pages[props.idRef.widgetPageIndex]
								.results[props.idRef.widgetItemIndex]
						}
					/>
				)
			case DisplayWidgetType.Brands:
			case DisplayWidgetType.Searches:
			case DisplayWidgetType.Text:
			case DisplayWidgetType.Question:
			case DisplayWidgetType.Attachment:
				throw new Error(`Item type ${output.type} is not supported in Bubble User`)
			default: {
				const _exhaustiveCheck: never = type
				const errorMessage = `Unknown widget type in Bubble User: ${_exhaustiveCheck}`
				Sentry.captureMessage(errorMessage)
				throw new Error(errorMessage)
			}
		}
	}

	return renderSelectedOutput()
}

export default BubbleUserWidgetSelector
