import React from "react"
import Styles from "./InputRange.module.scss"
import classNames from "classnames"

type Props = {
	value: number,
	labelValueUnit: string,
	min?: number,
	max: number,
	onChange: (value: number) => void,
	styles: {
		fullWidth?: boolean,
		color: "primary" | "base",
		size: "small" | "large",
	},
}

const InputRange = (props: Props) => {

	const labelClasses = classNames({
		[Styles.label_small]: props.styles?.size === "small",
		[Styles.label_large]: props.styles?.size === "large",
	})

	const inputClasses = classNames(
		Styles.input,
		{
			[Styles.input_fullwidth]: props.styles?.fullWidth,
			[Styles.input_color_primary]: props.styles?.color === "primary",
			[Styles.input_color_base]: props.styles?.color === "base",
			[Styles.input_size_small]: props.styles?.size === "small",
			[Styles.input_size_large]: props.styles?.size === "large",
		},
	)


	return <label className={Styles.wrapper}>
		<div className={labelClasses}>
			{props.value}
			{props.labelValueUnit}
		</div>
		<input type="range"
			className={inputClasses}
			defaultValue={props.value}
			min={props.min || 0}
			max={props.max}
			onChange={e => {
				props.onChange(Number(e.target.value))
			}}
		/>
	</label>
}

export default InputRange
