/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { forwardRef, useContext, useRef, useState, } from "react"
import Styles from "./PromptToolbar.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import AirplaneIcon from "deblank-common/src/assets/images/icon-library/send.svg"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import TextArea from "@/components/Form/TextArea/TextArea"
import classNames from "classnames"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useRecoilState, useRecoilValue } from "recoil"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import ThumbSelector from "./ThumbSelector/ThumbSelector"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { useConversationHandlers } from "@/hooks/useConversationHandlers"
import GradientLoadingAnimation from "@/components/GradientLoadingAnimation/GradientLoadingAnimation"
import { selectorsIntroQuestions } from "@/recoil/ConversationsRecord/Selectors/CurrentIntroQuestions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { introQuestions, UserMessageTypes } from "deblank-api-types"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { settersIntroQuestions } from "@/recoil/ConversationsRecord/Setters/CurrentIntroQuestions"
import InformationIcon from "deblank-common/src/assets/images/icon-library/info.svg"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import AttachFile from "./AttachFile/AttachFile"
import { useTestHelper } from "@/hooks/useTestHelper"

export type PromptAssistant = {
	prompt: string,
}

export type PromptToolbarHandle = {
	sendPrompt: (value: PromptAssistant) => void,
}

const PromptToolbar = forwardRef<PromptToolbarHandle>(() => {

	const conversationHandlers = useConversationHandlers()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [prompt, setPrompt,] = useRecoilState(selectorsUITemporary.userPromptState)
	const [_, setFileAttachment,] = useRecoilState(selectorsUITemporary.userFileAttachment)
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)
	const loadConversationState = useRecoilValue(selectorsConversations.loadConversationState)
	const outputIdSelected = useRecoilValue(selectorsUITemporary.userPromptOutputIdSelected)
	const currentIntroQuestion = useRecoilValue(selectorsIntroQuestions.currentIntroQuestion)
	const fileInputRef = useRef<HTMLInputElement>(null)
	const isColorsPlaygroundOpen = !!useRecoilValue(selectorsCommon.colorPlaygroundWidget)
	const isFontsPlaygroundOpen = !!useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const isImageModalOpen = !!useRecoilValue(selectorsCommon.imageModalWidget)
	const [fileError, setFileError,] = useState<string | null>(null)
	const addPendingMessage = settersAssistantResponseTools.useAddPendingMessageToConversation()
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const setOutputIdSelected = settersUiTemporary.useSetOutputIdSelected()
	const setIntroQuestions = settersIntroQuestions.useSetCurrentIntroQuestions()
	const currentConversation = useRecoilValue(selectorsConversations.currentConversation)
	const setConversationFilename = settersConversations.useSetConversationAttachment()
	const windowsSize = useWindowSize()
	const isMobile = windowsSize.isMobile || windowsSize.isTablet
	const testHelper = useTestHelper()

	const handleClearOutputIdSelected = () => {
		setOutputIdSelected({
			outputIdSelected: null,
		})
	}

	const handleOnSend = async () => {
		// eslint-disable-next-line max-len
		const attachedFilePrompt = "Extract the information from the attached guidelines file to get all the relevant information of the project."
		const userMessage = "Get information from the attached file"
		const hasFile = fileInputRef.current?.value
		const isIntroQuestionActive = currentIntroQuestion !== null

		// Reset intro questions if a file is attached and not starting a new chat
		if (hasFile && !showNewChat) {
			setIntroQuestions(null)
		}

		// Handle new conversation or pending message
		if (showNewChat) {
			await conversationHandlers.onCreateNewConversation({
				userMessage: hasFile ? attachedFilePrompt : prompt,
				prompt: hasFile ? userMessage : undefined,
				currentIntroQuestion: hasFile ? null : 0,
				attachedGuideline: hasFile ? {
					fileName: fileInputRef.current!.files![0].name,
				} : undefined,
			})
		} else {
			if (hasFile) {
				setConversationFilename({
					conversationId: currentConversation!.id,
					fileName: fileInputRef.current.files![0].name,
				})
			}
			addPendingMessage({
				type: UserMessageTypes.text,
				message: hasFile ? attachedFilePrompt : prompt,
				idRefs: outputIdSelected ? [outputIdSelected,] : undefined,
				attachedGuideline: hasFile ? {
					fileName: fileInputRef.current!.files![0].name,
				} : undefined,
			})
		}

		// Track conversation events
		if (isIntroQuestionActive) {
			if (hasFile) {
				trackConversationEvent({
					eventName: "SkipIntroductionQuestionsEvent",
					question: introQuestions[currentIntroQuestion].question,
				})
			} else {
				trackConversationEvent({
					eventName: "IntroductionQuestionEvent",
					question: introQuestions[currentIntroQuestion].question,
					answer: prompt,
				})
			}
		}

		// Clear prompt and reset selection
		setPrompt("")
		handleClearOutputIdSelected()

		if (hasFile) {
			fileInputRef.current.value = ""
		}
	}

	const handleOnChangePrompt = (value: string) => {
		setPrompt(value)
	}

	const renderActions = () => {

		const sanitizedPrompt = prompt.replace(/(\r\n|\n|\r)/g, "").trim()
		const isDisabled = sanitizedPrompt.length === 0
		return (
			<ButtonIcon
				type="button"
				iconSVGComponent={AirplaneIcon}
				onClick={handleOnSend}
				disabled={isDisabled || someMessageIsLoading || isSavingNewConversationName}
				testId={testHelper?.submitButton}
			/>
		)
	}

	const wrapperClasses = classNames(
		Styles.wrapper, {
		[Styles.with_selected_outputs]: outputIdSelected,
	})

	const containerClasses = classNames(
		Styles.container, {
		[Styles.is_disabled]: !!fileInputRef.current?.value,
	}
	)

	const RenderSelectedOutputs = () => {
		return (
			<>

				<section className={Styles.selected_outputs_header}>
					<p className={Styles.selected_outputs_title}>
						Send to chat
					</p>
					<span className={Styles.selected_outputs_separator} />
					<ButtonIcon type="button"
						iconSVGComponent={CrossIcon}
						onClick={handleClearOutputIdSelected}
						customStyles={{
							variant: "ghost",
						}}
					/>
				</section>
				<span className={Styles.selected_outputs_divider} />
				<section className={Styles.selected_content_widgets}>
					<ThumbSelector idRef={outputIdSelected!} />
				</section>
			</>
		)
	}

	const disabledTextArea = someMessageIsLoading
		|| loadConversationState
		|| ((isPinnedListOpen || isSidebarOpen) && isMobile)
		|| (isColorsPlaygroundOpen || isFontsPlaygroundOpen || isImageModalOpen)



	return (
		<>
			<section className={wrapperClasses}>
				{fileError && <section className={Styles.file_size_error_container}>
					<section className={Styles.file_size_error}>
						<InformationIcon />
						{fileError}
						<button
							onClick={() => setFileError(null)}
							type="button"
						>
							<CrossIcon />
						</button>
					</section>
				</section>}
				{outputIdSelected && RenderSelectedOutputs()}
				<section className={containerClasses}>
					{someMessageIsLoading && <GradientLoadingAnimation />}
					<div className={Styles.input_container}>
						<AttachFile
							fileInputRef={fileInputRef}
							onFileChange={setFileAttachment}
							onFileError={setFileError}
							onPromptChange={setPrompt}

						/>
						{!fileInputRef.current?.value && <TextArea
							// eslint-disable-next-line no-nested-ternary
							placeholder={currentIntroQuestion === null
								? isMobile
									? "Message the assistant"
									: "Your message to the assistant"
								: "Enter your answer"}
							value={prompt}
							onChange={handleOnChangePrompt}
							onEnterKeyDown={handleOnSend}
							disabled={disabledTextArea}
							truncateToOneLine={false}
							maxLength={500}
							triggerFocusDeps={[showNewChat, !!outputIdSelected,]}
						/>}
					</div>
					<section className={Styles.actions_container} >
						<div className={Styles.primary_actions_container}>
							{renderActions()}
						</div>
					</section>
				</section>
			</section>
		</>
	)
})

PromptToolbar.displayName = "PromptToolbar"

export default PromptToolbar
