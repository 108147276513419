/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable max-lines-per-function */
import React, { useContext, useMemo, useRef, useState, } from "react"
import Styles from "./FontsPlayground.module.scss"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import UnionIcon from "deblank-common/src/assets/images/icon-library/repeat-prompt.svg"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import {
	DisplayWidgetType, EntryFontsResult,
	Pairing
} from "deblank-api-types"
import { TrackConversationEventContext } from "../../TrackConversationEventProvider"
import { useSanitizedStates } from "@/hooks/useSanitazedTexts"
import { useRecoilValue } from "recoil"
import { DownloadHelper } from "@/utils/exportHelper"
import usePresetActions from "@/components/Actions/usePresetActions"
import { produce } from "immer"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import Tooltip from "@/components/Tooltip/Tooltip"
import FontsPlaygroundDesktop from "./FontsPlaygroundDesktop/FontsPlaygroundDesktop"
import { getBodyDefaulState, getSubtitleDefaulState, getTitleDefaulState } from "./FontsPlaygroundHelper"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useWindowSize } from "@/hooks/useWindowsSize"
import FontsPlaygroundMobile from "./FontsPlaygroundMobile/FontsPlaygroundMobile"

export const FontsPlayground = () => {

	const widget = useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const widgetSelectData = useMemo(() => (widget), [])
	const output = useRecoilValue(selectorsWidgets.outputById(widgetSelectData!.idRef.widgetId)) as EntryFontsResult
	const windowsSize = useWindowSize()
	const widgetType = DisplayWidgetType.Fonts
	const ref = useRef<HTMLDivElement>(null)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const setFontPlaygroundIdRef = settersCommon.useSetFontPlaygroundIdRef()

	const updateNotification = settersAssistantResponseTools.useDispatchUpdateNotifications()
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: widgetSelectData!.idRef,
		widgetType: widgetType,
	})

	const fontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.widgetData.slug))
	const baseFontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.metadata.baseFont || undefined))


	const [bodyTextState, setBodyTextState,] = useState(getBodyDefaulState(widgetSelectData?.metadata.pairing === Pairing.title
		? baseFontDetails!.metadata.weightsCode
		: fontDetails!.metadata.weightsCode))

	const [subtitleTextState, setSubtitleTextState,] = useState(
		getSubtitleDefaulState(widgetSelectData?.metadata.pairing === Pairing.title
			? baseFontDetails!.metadata.weightsCode
			: fontDetails!.metadata.weightsCode))

	const [titleTextState, setTitleTextState,] = useState(
		getTitleDefaulState(widgetSelectData?.metadata.pairing === Pairing.paragraph
			? baseFontDetails!.metadata.weightsCode
			: fontDetails!.metadata.weightsCode))


	const { bodyProps, titleProps, subtitleProps,
		handleBodyOnBlur, handleTitleOnBlur, handleBodyOnChange, handleSubtitleOnChange, handleTitleOnChange,
		handleSubtitleOnBlur, bodyValue, subtitleValue, titleValue,
	} = useSanitizedStates(
		widgetSelectData!.metadata.title,
		widgetSelectData!.metadata.description,
		widgetSelectData!.metadata.bodyText,
	)



	if (!widgetSelectData) {
		return null
	}

	const handleOnClose = () => {
		const newOutput = produce(output, (draft) => {
			draft.metadata.title = titleValue
			draft.metadata.description = subtitleValue
			draft.metadata.bodyText = bodyValue
		})
		updateNotification({
			notifications: [{
				outputs: [newOutput,],
			},],
		})
		setFontPlaygroundIdRef({ idRef: null, })
	}

	const handleDownload = async () => {
		await DownloadHelper.fromRefTemplate(ref)
		trackConversationEvent({
			eventName: "Download",
			widgetType: widgetType,
			responseId: widgetSelectData.idRef.messageId,
			outputId: widgetSelectData.idRef.widgetId,
		})
	}

	const commonActions = () => {
		return (
			<ul className={Styles.common_actions_container}>
				<li>
					<Tooltip
						tooltipContent={<>Send to chat</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={() => {
								trackConversationEvent({
									eventName: "SendToChat",
									outputId: widgetSelectData.idRef.widgetId,
									widgetType: DisplayWidgetType.Fonts,
									responseId: widgetSelectData.idRef.messageId,
								})
								sendToChatPresetAction.onClick()
								handleOnClose()
							}}
							iconSVGComponent={UnionIcon}
							disabled={sendToChatPresetAction.isDisabled}
						/>
					</Tooltip >
				</li>
				<li>
					<Tooltip
						tooltipContent={<>Download</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={handleDownload}
							iconSVGComponent={DownloadIcon}
						/>
					</Tooltip>
				</li>
				<li>
					<Tooltip
						tooltipContent={<>Pin</>}
						customStyles={{ position: "bottom", }}
					>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={() => {
								pinPresetAction.onClick()
								handleOnClose()
							}}
							iconSVGComponent={pinPresetAction.icon}
							disabled={pinPresetAction.isDisabled}
						/>
					</Tooltip>
				</li>
			</ul>
		)
	}



	return (

		<>
			<nav className={Styles.actions_container}>
				{commonActions()}
				<ul className={Styles.action_list}>
					<li className={Styles.close_button_container}>
						<ButtonIcon
							customStyles={{ variant: "ghost", }}
							type="button"
							onClick={handleOnClose}
							iconSVGComponent={CrossIcon}
						/>
					</li>
				</ul>
			</nav>
			<section className={Styles.wrapper}>
				{windowsSize.isMobile
					? <FontsPlaygroundMobile
						body={{
							textState: bodyTextState,
							onChange: handleBodyOnChange,
							onChangeTextState: setBodyTextState,
							onBlur: handleBodyOnBlur,
							elementProps: bodyProps,
							value: bodyValue,
						}}
						subtitle={{
							textState: subtitleTextState,
							onChangeTextState: setSubtitleTextState,
							onBlur: handleSubtitleOnBlur,
							onChange: handleSubtitleOnChange,
							elementProps: subtitleProps,
							value: subtitleValue,
						}}
						title={{
							textState: titleTextState,
							onChangeTextState: setTitleTextState,
							onBlur: handleTitleOnBlur,
							onChange: handleTitleOnChange,
							elementProps: titleProps,
							value: titleValue,
						}}
					/>
					: <FontsPlaygroundDesktop
						body={{
							textState: bodyTextState,
							onChange: setBodyTextState,
							onBlur: handleBodyOnBlur,
							elementProps: bodyProps,
						}}
						subtitle={{
							textState: subtitleTextState,
							onChange: setSubtitleTextState,
							onBlur: handleSubtitleOnBlur,
							elementProps: subtitleProps,
						}}
						title={{
							textState: titleTextState,
							onChange: setTitleTextState,
							onBlur: handleTitleOnBlur,
							elementProps: titleProps,
						}}
					/>}

			</section>
		</>
	)
}
