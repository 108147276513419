import { trpc } from "@/providers/TRPCProvider"
import { ConversationMetadata, DisplayWidgetType, OutputWithoutIds, UserMessageTypes, introQuestions } from "deblank-api-types"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useRecoilState } from "recoil"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"

export const useConversationHandlers = () => {

	const createNewConversation = trpc.assistant.conversation.createConversation.useMutation()

	const addNewConversation = settersConversations.useAddNewConversation()
	const addCreateNotification = settersAssistantResponseTools.useDispatchCreateNotifications()
	const addPendingMessage = settersAssistantResponseTools.useAddPendingMessageToConversation()
	const clearCurrentConversationId = settersConversations.useClearCurrentConversationId()
	const setShowNewChat = settersConversations.useSetShowNewChat()
	const setIsOpenPinned = settersUiTemporary.useSetIsOpenPinned()
	const [_, setPrompt,] = useRecoilState(selectorsUITemporary.userPromptState)

	const onCreateNewConversation = async (params: {
		userMessage: string,
		currentIntroQuestion: number | null,
		prompt?: string,
		attachedGuideline?: {
			fileName: string,
		},
	}) => {
		setPrompt("")
		clearCurrentConversationId()
		setIsOpenPinned({ isOpen: false, })

		const metadata: ConversationMetadata = {
			conversationName: params.userMessage,
			outputs: [],
			messages: [],
		}

		const questionOutput: OutputWithoutIds = {
			type: DisplayWidgetType.Question,
			metadata: null,
			data: {
				isPaginated: false,
				results: [
					{
						message: introQuestions[0].question,
						example: introQuestions[0].example,
						currentStep: 0,
						totalSteps: introQuestions.length,
					},
				],
			},
		}

		const newConversation = await createNewConversation.mutateAsync({
			metadata: metadata,
		})

		addNewConversation({
			userMessage: params.userMessage,
			conversationId: newConversation.id,
			createDate: newConversation.createdAt,
			currentIntroQuestion: params.currentIntroQuestion,
			attachedGuideline: params.attachedGuideline,
		})

		addCreateNotification({
			notifications: [{
				internalMessageForAgent: "The user was asked with a initial question",
				output: questionOutput,
				userMessage: null,
				relatedActions: [],
			},],
		})

		addPendingMessage({
			type: UserMessageTypes.text,
			message: params.userMessage,
			prompt: params.prompt,
		})

		setShowNewChat({ showNewChatView: false, })
	}

	return ({
		onCreateNewConversation,
	})
}
