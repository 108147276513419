import React, { useContext, useEffect, useRef, useState } from "react"
import { CommonWidgetBrandsProps } from "../WidgetBrandsTypes"
import Styles from "./WidgetConversationBrands.module.scss"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ExternalLinkModal } from "./ExternalLinkModal/ExternalLinkModal"
import { IdRefType } from "deblank-api-types"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { AnimatePresence } from "framer-motion"
import BrandCard from "./BrandCard/BrandCard"

const WidgetConversationBrands = (
	props: {
		activePageIndex: number,
	} & CommonWidgetBrandsProps
) => {
	const [showModal, setShowModal,] = useState(false)
	const [activePageIndex, setActivePageIndex,] = useState(0)

	const [maxHeight, setMaxHeight,] = useState(0)
	const refsArrays = useRef<React.RefObject<HTMLDivElement>[]>([])

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const windowSize = useWindowSize()
	const isMobile = windowSize.isTablet || windowSize.isMobile
	const results = props.widgetDetails.data.pages[props.activePageIndex].results


	const handlePrev = () => {
		setActivePageIndex((prev) => prev - 1)
		trackConversationEvent({
			eventName: "ClickOnChangeBrandPage",
			brandId: results[activePageIndex].id,
			pageIndex: activePageIndex,
		})
	}

	const handleNext = () => {
		setActivePageIndex((prev) => prev + 1)
		trackConversationEvent({
			eventName: "ClickOnChangeBrandPage",
			brandId: results[activePageIndex].id,
			pageIndex: activePageIndex,
		})
	}

	const handleClickOnExternalLink = (params: {
		brandId: string,
		link: string,
	}) => {
		setShowModal(true)
		trackConversationEvent({
			eventName: "ClickOnOpenBrandExternalLinkModal",
			brandId: params.brandId,
			link: params.link,
		})
	}

	useEffect(() => {
		refsArrays.current = results.map((_, i) => refsArrays.current[i] || React.createRef<HTMLDivElement>())
	}, [results.length,])

	useEffect(() => {
		if (isMobile) { return }

		const refs = refsArrays.current
		if (refs.some((ref) => ref.current === null)) { return }

		const heights = refs.map((ref) => ref.current?.getBoundingClientRect().height || 0)
		const max = Math.max(...heights)
		const roundedMax = Math.floor(max)

		if (roundedMax > maxHeight) {
			setMaxHeight(roundedMax)
		}
	}, [isMobile, results.length, activePageIndex,])

	return results.length > 0 && (
		<>
			<section className={Styles.cards_wrapper}
				style={{
					"minHeight": isMobile ? "auto" : `${maxHeight}px`,
				}}
			>
				{results.map((card, i) => {
					const n = results.length
					const cardPosition = (i - activePageIndex + n) % n

					return (
						<BrandCard ref={refsArrays.current[i]}
							key={card.id}
							idRef={{
								type: IdRefType.WidgetWithPaginationItem,
								messageId: props.idRef.messageId,
								widgetId: props.idRef.widgetId,
								widgetPageIndex: props.activePageIndex,
								widgetItemIndex: i,
								widgetItemId: card.id,
							}}
							widgetType={props.widgetDetails.type}
							widgetDetail={card}
							minHeight={maxHeight}
							cardPosition={cardPosition}
							isActive={activePageIndex === i}
							showArrowsActions={results.length > 1}
							onClickExternalLink={handleClickOnExternalLink}
							onNextCard={{
								onClick: handleNext,
								isDisabled: i === (results.length - 1),
							}}
							onPreviousCard={{
								onClick: handlePrev,
								isDisabled: i === 0,
							}}
						/>
					)
				})}
			</section >

			<AnimatePresence>
				{showModal
					&& <ExternalLinkModal
						closeModal={() => setShowModal(false)}
						url={results[activePageIndex].link!}
					/>
				}
			</AnimatePresence>
		</>
	)
}

export default WidgetConversationBrands
