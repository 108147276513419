import React, { useContext, useEffect, useRef } from "react"
import Styles from "./FontWeightToolbar.module.scss"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { TextState, weightValue } from "../../../FontsPlaygroundHelper"
import { FontFromCache } from "@/recoil/FontCache/Types"
import classNames from "classnames"

type Props = {
	state: TextState,
	font: FontFromCache,
	onChange: React.Dispatch<React.SetStateAction<TextState>>,
}

const FontWeightToolbar = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [optionRefs, setOptionRefs,] = React.useState<{
		[weight: string]: HTMLButtonElement,
	}>({})

	const handleOnChangeWeight = async (
		weight: number
	) => {
		await trackConversationEvent({
			eventName: "ChangeFontWeight",
			weight: weight,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				fontWeight: weight,
			}
		})
	}

	useEffect(() => {
		if (optionRefs && optionRefs[props.state.fontWeight] && props.state.fontWeight) {
			const ref = optionRefs[props.state.fontWeight]
			if (ref) {
				ref.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
				})
			}
		}
	}, [optionRefs,])


	const options = Array.from(new Set(props.font.metadata.weightsCode,)).map(w => {
		const weight = w.toString()
		const label = weightValue[weight as keyof typeof weightValue]

		const buttonClass = classNames({
			[Styles.option]: true,
			[Styles.selected]: props.state.fontWeight === w,
		})

		return <button
			ref={(ref) => {
				if (ref && !optionRefs[weight]) {
					setOptionRefs(prev => {
						return {
							...prev,
							[weight]: ref,
						}
					})
				}
			}}
			className={buttonClass}
			key={weight}
			onClick={() => handleOnChangeWeight(w)}
			style={{ fontWeight: w, }}
		>
			{label}
		</button>
	})

	return (
		<div className={Styles.options_row}>
			{options}
		</div>
	)
}

export default FontWeightToolbar