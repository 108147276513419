/* eslint-disable complexity */
import Tooltip from "@/components/Tooltip/Tooltip"
import React, { useState } from "react"
import Styles from "./AttachFile.module.scss"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import AttachmentIcon from "deblank-common/src/assets/images/icon-library/attack.svg"
import AttachmentThumbnail from "@/assets/attachment_thumbnail.png"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import classNames from "classnames"
import { selectorsIntroQuestions } from "@/recoil/ConversationsRecord/Selectors/CurrentIntroQuestions"

type Props = {
	fileInputRef: React.RefObject<HTMLInputElement>,
	onPromptChange: (prompt: string) => void,
	onFileChange: (file: File) => void,
	onFileError: (error: string | null) => void,
}

const AttachFile = (props: Props) => {

	const [temporaryFilename, setTemporaryFilename,] = useState<string>("")
	const currentConversation = useRecoilValue(selectorsConversations.currentConversation)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const windowsSize = useWindowSize()
	const isMobile = windowsSize.isMobile || windowsSize.isTablet
	const currentIntroQuestion = useRecoilValue(selectorsIntroQuestions.currentIntroQuestion)

	const handleOnClear = () => {
		props.onPromptChange("")
		setTemporaryFilename("")
		if (props.fileInputRef.current) {
			props.fileInputRef.current.value = ""
		}
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onFileError(null)
		props.onFileChange(event.target.files![0])
		if (props.fileInputRef.current) {
			const fileSizeInMB = Number((props.fileInputRef.current.files![0].size / (1024 * 1024)).toFixed(2))
			if (fileSizeInMB > 50) {
				props.onFileError("File exceeds 50MB")
				props.fileInputRef.current.value = ""
				setTemporaryFilename("")
				props.onPromptChange("")
			} else {
				props.onPromptChange(props.fileInputRef.current.files![0].name || "")
				setTemporaryFilename(props.fileInputRef.current.files![0].name || "")
			}
			if (props.fileInputRef.current.files![0].type !== "application/pdf") {
				props.onFileError("File must be a PDF")
				props.fileInputRef.current.value = ""
				setTemporaryFilename("")
				props.onPromptChange("")
			}
		}

	}

	const attachmentContainerClasses = classNames(
		Styles.attachment_container, {
		[Styles.has_file]: !!props.fileInputRef.current?.value,
	})

	return <Tooltip
		disabled={!currentConversation?.attachedGuideline || showNewChat || someMessageIsLoading}
		tooltipContent={<div className={Styles.tooltip_wrapper}>
			<p className={Styles.tooltip_title}>A file is already attached</p>
			<p className={Styles.tooltip_subtitle}>Start a new chat to upload a new one.</p>
			<div className={Styles.tooltip_pdf_name}>
				<AttachmentIcon />
				<span>{currentConversation?.attachedGuideline?.fileName}</span>
			</div>
		</div>}
		customStyles={{
			position: isMobile ? "right" : "top",
			fullWidth: !!props.fileInputRef.current?.value,
			containerNotClickable: !!props.fileInputRef.current?.value,
		}}
	>
		<div className={attachmentContainerClasses}>
			<div className={Styles.attachment_name_and_icon}>
				<ButtonIcon
					type="button"
					iconSVGComponent={AttachmentIcon}
					onClick={() => {
						if (props.fileInputRef.current) {
							props.fileInputRef.current.click()
						}
					}}
					customStyles={{
						variant: "ghost",
					}}
					disabled={someMessageIsLoading
						|| (!!currentConversation?.attachedGuideline && !showNewChat)
						|| !!props.fileInputRef.current?.value
						|| currentIntroQuestion !== null
						|| showNewChat
					}
				/>
				{props.fileInputRef.current?.value && <div className={Styles.attachment_name}>
					{temporaryFilename}
				</div>}
			</div>
			{props.fileInputRef.current?.value && <ButtonIcon
				onClick={handleOnClear}
				disabled={someMessageIsLoading}
				iconSVGComponent={CrossIcon}
				type="button"
				customStyles={{
					variant: "ghost",
				}}
			/>}
			<input
				type="file"
				accept="application/pdf"
				onChange={handleFileChange}
				ref={props.fileInputRef}
				style={{ display: "none", }}
			/>
			{(!currentConversation?.attachedGuideline)
				&& currentIntroQuestion === null
				&& !props.fileInputRef.current?.value
				&& !showNewChat
				&& <div className={Styles.attachment_tooltip_wrapper}>
					<img src={AttachmentThumbnail} alt="Attachment thumbnail" />
					<div className={Styles.attachment_tooltip_body}>
						<div className={Styles.attachment_heading}>
							<h3>Upload Brand guideline</h3>
							<span className={Styles.beta_badge}>Beta</span>
						</div>
						<p>Upload an existing brand guideline to extract the information and work on it.</p>
					</div>
				</div>}
		</div>
	</Tooltip>
}

export default AttachFile
