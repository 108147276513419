import { Variants, HTMLMotionProps } from "framer-motion"

const actionsListOpenDownVariants: Variants = {
	hidden: {
		opacity: 0,
		scale: 0.9,
		top: 36,
		left: 0,
	},
	visible: {
		opacity: 1,
		scale: 1,
		top: 36,
		left: 0,
	},
}

const actionsListOpenUpVariants: Variants = {
	hidden: {
		opacity: 0,
		scale: 0.9,
		top: -108,
		left: 0,
	},
	visible: {
		opacity: 1,
		scale: 1,
		top: -108,
		left: 0,
	},
}


const actionsListTransition: HTMLMotionProps<"div">["transition"] = {
	type: "tween",
	duration: 0.15,
	ease: [0.4, 0.14, 0.3, 1,],
}


export const actionListOpenUpMotionProps = {
	variants: actionsListOpenUpVariants,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: actionsListTransition,
}

export const actionListOpenDownMotionProps = {
	variants: actionsListOpenDownVariants,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: actionsListTransition,
}
