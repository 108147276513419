import { selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { ConversationStateSyncStatus, UserHistoryConversationsById, } from "../Types"
import { prefixRecoilKey } from "./Common"
import { Conversation, UserHistoryConversations } from "deblank-api-types"


const currentConversation = selector<Conversation | null>({
	key: prefixRecoilKey("currentConversation"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		return conversations[activeConversationId!]
			? conversations[activeConversationId!]
			: null
	},
})

const activeConversationId = selector<string | null>({
	key: prefixRecoilKey("activeConversationId"),
	get: ({ get, }) => {
		const { activeConversationId, } = get(atomConversationsRecord)
		return activeConversationId
	},
})

const showNewChat = selector<boolean>({
	key: prefixRecoilKey("isNewChat"),
	get: ({ get, }) => {
		const { showNewChatView, } = get(atomConversationsRecord)
		return showNewChatView
	},
})

const conversationList = selector<UserHistoryConversations[] | null>({
	key: prefixRecoilKey("conversationList"),
	get: ({ get, }) => {
		const { userHistoryConversations, } = get(atomConversationsRecord)
		const userHistoryConversationsArray = userHistoryConversations ? Object.values(userHistoryConversations) : []
		const sortedConversations = userHistoryConversationsArray.sort((a, b) => {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
		})

		return sortedConversations.length > 0 ? sortedConversations : null
	},
})

const historyConversations = selector<UserHistoryConversationsById | null>({
	key: prefixRecoilKey("historyConversations"),
	get: ({ get, }) => {
		const { userHistoryConversations, } = get(atomConversationsRecord)
		return userHistoryConversations ? userHistoryConversations : null
	},
})

const conversationSaveState = selector<ConversationStateSyncStatus>({
	key: prefixRecoilKey("conversationSyncState"),
	get: ({ get, }) => {
		const { conversationStateSyncStatus, } = get(atomConversationsRecord)
		return conversationStateSyncStatus
	},
})

const loadConversationState = selector<boolean>({
	key: prefixRecoilKey("loadConversationState"),
	get: ({ get, }) => {
		const { loadActiveConversation, } = get(atomConversationsRecord)
		return loadActiveConversation
	},
})

const isSavingNewConversationName = selector<boolean>({
	key: prefixRecoilKey("isEditingConversationName"),
	get: ({ get, }) => {
		const { isSavingNewConversationName, } = get(atomConversationsRecord)
		return isSavingNewConversationName
	},
})


export const selectorsConversations = {
	currentConversation,
	activeConversationId,
	showNewChat,

	conversationList,
	historyConversations,
	conversationSaveState,
	loadConversationState,
	isSavingNewConversationName,
}
