import { atom } from "recoil"
import { AtomUITemporary } from "./Types"


const defaultValue: AtomUITemporary = {
	userPrompt: {
		message: "",
		outputIdSelected: null,
		fileAttachment: null,
	},
	sidebarIsOpen: false,
	pinnedIsOpen: false,
	debugPanelIsOpen: false,
	instagramBannerIsOpen: false,
}

export const atomUITemporary = atom<AtomUITemporary>({
	key: "UITemporary",
	default: defaultValue,
})
