import React, { useContext } from "react"
import Styles from "./TextAlignToolbar.module.scss"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { TextAlign, textAlignNextStep, TextState } from "../../../FontsPlaygroundHelper"
import { FontFromCache } from "@/recoil/FontCache/Types"
import classNames from "classnames"

type Props = {
	state: TextState,
	font: FontFromCache,
	onChange: React.Dispatch<React.SetStateAction<TextState>>,
}

const TextAlignToolbar = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleOnChangeTextAlign = async (value: TextAlign) => {
		await trackConversationEvent({
			eventName: "ChangeTextAligment",
			aligment: value,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				textAlign: value,
			}
		})
	}

	const renderOption = (value: TextAlign, icon: React.FC<React.SVGProps<SVGElement>>) => {
		const buttonClass = classNames({
			[Styles.option]: true,
			[Styles.selected]: props.state.textAlign === value,
		})
		const Icon = icon
		return (
			<button
				className={buttonClass}
				key={value}
				onClick={() => handleOnChangeTextAlign(value)}
			>
				<Icon />
			</button>
		)
	}

	return (
		<div className={Styles.options_row}>
			{renderOption(TextAlign.left, textAlignNextStep.left.icon)}
			{renderOption(TextAlign.center, textAlignNextStep.center.icon)}
			{renderOption(TextAlign.right, textAlignNextStep.right.icon)}
			{renderOption(TextAlign.justify, textAlignNextStep.justify.icon)}
		</div>
	)
}

export default TextAlignToolbar