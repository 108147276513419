import React from "react"
import * as Sentry from "@sentry/react"
import { DisplayWidgetType, IdRefWidgetWithPaginationItem } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { WidgetColors } from "@/components/WidgetsByType/Colors/WidgetColors"
import { WidgetFonts } from "@/components/WidgetsByType/Fonts/WidgetFonts"
import { WidgetImages } from "@/components/WidgetsByType/Images/WidgetImages"
import { WidgetMockups } from "@/components/WidgetsByType/Mockups/WidgetMockups"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
}

const ThumbSelector = (props: Props) => {
	const output = useRecoilValue(selectorsWidgets.outputById(props.idRef.widgetId))
	const type = output.type

	switch (type) {
		case DisplayWidgetType.Colors:
			return (
				<WidgetColors.ToolbarThumb
					idRef={props.idRef}
					widgetType={output.type}
					widgetItemDetails={
						output.data
							.pages[props.idRef.widgetPageIndex]
							.results[props.idRef.widgetItemIndex]
					}
				/>
			)
		case DisplayWidgetType.Fonts:
			return (
				<WidgetFonts.ToolbarThumb
					idRef={props.idRef}
					widgetType={output.type}
					metadata={output.metadata}
					widgetItemDetails={
						output.data
							.pages[props.idRef.widgetPageIndex]
							.results[props.idRef.widgetItemIndex]
					}
				/>
			)
		case DisplayWidgetType.Searches:
		case DisplayWidgetType.Images:
			return (
				<WidgetImages.ToolbarThumb
					idRef={props.idRef}
					widgetType={output.type}
					widgetItemDetails={
						output.data.isPaginated
							? output.data.pages[props.idRef.widgetPageIndex].results[props.idRef.widgetItemIndex]
							: output.data.results[props.idRef.widgetItemIndex]
					}
				/>
			)
		case DisplayWidgetType.MockupFonts:
		case DisplayWidgetType.MockupColors:
			return (
				<WidgetMockups.ToolbarThumb
					idRef={props.idRef}
					widgetType={output.type}
					widgetItemDetails={
						output.data
							.pages[props.idRef.widgetPageIndex]
							.results[props.idRef.widgetItemIndex]
					}
				/>
			)
		case DisplayWidgetType.Brands:
		case DisplayWidgetType.AccessibilityColors:
		case DisplayWidgetType.ColorsExplanations:
		case DisplayWidgetType.FontsExplanations:
		case DisplayWidgetType.Text:
		case DisplayWidgetType.Question:
		case DisplayWidgetType.Attachment:
			throw new Error(`Item type ${output.type} is not supported in prompt toolbar`)
		default: {
			const _exhaustiveCheck: never = type
			const errorMessage = `Unknown widget type in prompt toolbar: ${_exhaustiveCheck}`
			Sentry.captureMessage(errorMessage)
			throw new Error(errorMessage)
		}

	}
}

export default ThumbSelector
