import React, { useState } from "react"
import Styles from "./BubbleAssistantQuestion.module.scss"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"
import classNames from "classnames"
import Button from "@/components/Buttons/Button/Button"
import ChevronRightIcon from "deblank-common/src/assets/images/icon-library/chevron-right-alt.svg"
import { IdRefWidget } from "deblank-api-types"
import { useTestHelper } from "@/hooks/useTestHelper"

type ChatBubbleProps = {
	idRef: IdRefWidget,
	message: string,
	example: string,
	currentStep: number,
	totalSteps: number,
	isLastMessage: boolean,
	isLoading: boolean,
	onSkip: () => void,
}

const BubbleAssistantQuestion = (props: ChatBubbleProps) => {
	const [isLoading, setIsLoading,] = useState(false)
	const testHelper = useTestHelper()

	const renderStepMark = (stepIndex: number) => {
		const classes = classNames({
			[Styles.step_mark]: true,
			[Styles.is_completed]: props.currentStep >= stepIndex,
		})

		return (
			<div key={`step-${stepIndex}`} className={classes} />
		)
	}

	const handleOnClick = () => {
		setIsLoading(true)
		props.onSkip()
	}

	return (
		<div className={Styles.bubble}
			id={props.idRef.widgetId}>
			<div className={Styles.widget_top_row}>
				<div className={Styles.title_container}>
					<StarIcon />
					<p>Deblank</p>
				</div>
			</div>

			<div className={Styles.widget_answer_container}>
				<div className={Styles.left_column} />

				<div className={Styles.text_response}>
					<p className={Styles.question}>
						{props.message}
					</p>
					<p className={Styles.example}>
						{props.example}
					</p>

					{props.isLastMessage && (
						<div className={Styles.progress_container}>
							<div className={Styles.steps_container}>
								<p>
									{props.currentStep + 1}/{props.totalSteps}
								</p>

								<div className={Styles.steps_control_bar}
									style={{ "--steps_amount": props.totalSteps, } as React.CSSProperties}
								>
									{new Array(props.totalSteps).fill(null)
										.map((_, index) => {
											return renderStepMark(index)
										})
									}
								</div>
							</div>


							<Button onClick={handleOnClick}
								disabled={props.isLoading || isLoading}
								type="button"
								customStyles={{
									variant: "tertiary",
								}}
								icon={{
									SVGComponent: ChevronRightIcon,
									position: "right",
								}}
								testId={testHelper?.skipQuestions}
							>
								Skip questions
							</Button>
						</div >
					)}
				</div >
			</div >
		</div >
	)
}

export default BubbleAssistantQuestion
