import React, { useContext, useState } from "react"
import Styles from "./ColorItem.module.scss"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ColorUtils } from "@/utils/ColorUtils"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { DownloadHelper } from "@/utils/exportHelper"
import classNames from "classnames"
import {
	IdRefWidgetWithPaginationItem, EntryColorsResponse, DisplayWidgetType,
} from "deblank-api-types"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import { useTestHelper } from "@/hooks/useTestHelper"


type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: EntryColorsResponse,
	widgetType: DisplayWidgetType,
}

const ColorItem = (props: Props) => {

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const testHelper = useTestHelper()

	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)

	const setColorPlayground = settersCommon.useSetColorPlaygroundIdRef()

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleDownload = async () => {
		const widgetIdDom = ScreenshotIdManager.getElementFromDocument({
			widgetUiSection: WidgetUiSectionId.Conversation,
			idRef: props.idRef,
		})!
		await DownloadHelper.fromTemplate(widgetIdDom)
		trackConversationEvent({
			eventName: "Download",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const handleOpenPlayground = () => {
		trackConversationEvent({
			eventName: "OpenPlayground",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
		})
		setColorPlayground({ idRef: props.idRef, })
	}


	const actionList: widgetAction[] = [
		{
			text: "Open",
			icon: OpenIcon,
			onClick: handleOpenPlayground,
		},
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Download",
			icon: DownloadIcon,
			onClick: handleDownload,
		},
	]

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
		}
	)

	return (
		<div className={wrapperClasses}
			{...testHelper?.outputMetadata.colors({
				...props.widgetData,
				messageId: props.idRef.messageId,
			})}
		>
			<article
				id={
					ScreenshotIdManager.assignId({
						widgetUiSection: WidgetUiSectionId.Conversation,
						idRef: props.idRef,
					})
				}
				className={Styles.palette}>
				{props.widgetData.palette.map((color, index) => (
					<div key={`${index}-${color}`}
						className={Styles.color_item}
						style={{ "--current_color": color, } as React.CSSProperties}
					>
						<button
							type="button"
							className={Styles.copy_button}
							onClick={handleOpenPlayground}
							style={{ "--fg_color": ColorUtils.getForegroundColor(color), } as React.CSSProperties}
							disabled={areMessagesLoading || isSavingNewConversationName}
						/>
					</div>
				))}
			</article>

			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "colors",
				}}
			/>
		</div>
	)
}

export default ColorItem
