import React, { useContext } from "react"
import Button from "@/components/Buttons/Button/Button"
import Styles from "./LoginPage.module.scss"
import { AuthenticationContext } from "@/providers/AuthProvider"
import { UserContext } from "@/providers/UserProvider"
import Logo from "deblank-common/src/assets/images/brand/deblank.svg"

const LoginPage = () => {
	const userContext = useContext(UserContext)
	const authenticationContext = useContext(AuthenticationContext)

	const handleLogin = () => {
		authenticationContext.signInWithGoogle()
	}

	const handleLogout = () => {
		authenticationContext.userSignOut()
	}

	return (
		<section className={Styles.container}>
			<header className={Styles.header}>
				<Logo className={Styles.logo} />
			</header>

			{userContext.user
				? <div className={Styles.button_container}>
					<Button
						type="button"
						onClick={handleLogout}
						customStyles={{
							variant: "primary",
							size: "large",
							fullWidth: true,
						}}>
						Logout
					</Button>
				</div>

				: <div className={Styles.content}>
					<h1>Deblank Assistant</h1>
					<p className={Styles.message}>
						{"You're here early! Join the Alpha Version and be among the first to test the assistant."}
					</p>
					<div className={Styles.button_container}>
						<Button
							type="button"
							onClick={handleLogin}
							customStyles={{
								variant: "primary",
								size: "large",
								fullWidth: true,
							}}>
							Join us now
						</Button>
					</div>

				</div>
			}
			<span className={Styles.gradient} />
		</section>
	)
}

export default LoginPage
