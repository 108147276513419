import React, { useContext } from "react"
import Styles from "./PlaygroundToolbar.module.scss"
import StylesTextBlockManager from "../TextPlayground.module.scss"
import { FontFromCache } from "@/recoil/FontCache/Types"
import { textAlignNextStep, TextState, weightValue } from "../../../FontsPlaygroundHelper"
import IconLineHeight from "deblank-common/src/assets/images/icon-library/line-height.svg"
import IconLetterSpacing from "deblank-common/src/assets/images/icon-library/letter-spacing.svg"
import InputRange from "@/components/InputRange/InputRange"
import InputPercentage from "@/components/InputPercentage/InputPercentage"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import Select from "@/components/Select/Select"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"

type Props = {
	state: TextState,
	font: FontFromCache,
	messageId: string,
	onChange: React.Dispatch<React.SetStateAction<TextState>>,
}



const PlaygroundToolbar = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleOnChangeTextAlign = async () => {
		await trackConversationEvent({
			eventName: "ChangeTextAligment",
			aligment: textAlignNextStep[props.state.textAlign].nextAlignvalue,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				textAlign: textAlignNextStep[prev.textAlign].nextAlignvalue,
			}
		})
	}

	const handleOnChangeWeight = async (
		weight: number
	) => {
		await trackConversationEvent({
			eventName: "ChangeFontWeight",
			weight: weight,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				fontWeight: weight,
			}
		})
	}

	const handleOnChangeLineHeight = async (value: number) => {
		await trackConversationEvent({
			eventName: "ChangeTextLineHeight",
			lineHeight: value,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				lineHeight: {
					...prev.lineHeight,
					value: value,
				},
			}
		})
	}

	const handleOnChangeLetterSpacing = async (value: number) => {

		await trackConversationEvent({
			eventName: "ChangeTextLetterSpacing",
			letterSpacing: value,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				letterSpacing: {
					...prev.letterSpacing,
					value: value,
				},
			}
		})
	}

	const handleOnChangeFontSize = async (value: number) => {
		await trackConversationEvent({
			eventName: "ChangeFontSize",
			size: value,
			fontSlug: props.font.metadata.slug,
		})
		props.onChange(prev => {
			return {
				...prev,
				fontSize: {
					...prev.fontSize,
					value: value,
				},
			}
		})
	}


	return (
		<section className={Styles.wrapper}>
			<h5 className={Styles.font_name}>
				{props.font.metadata.name}
				<a
					className={Styles.link_vendor}
					href={props.font.metadata.linkToVendor}
					target="_blank"
					rel="noreferrer"
					onClick={async () => {
						await trackConversationEvent({
							eventName: "ClickGetFont",
							fontName: props.font.metadata.name,
							responseId: props.messageId,
						})
					}}
				>
					<LinkIcon />
				</a>
			</h5>
			<div className={`${Styles.container_actions} ${StylesTextBlockManager.actions}`}>
				<InputRange
					value={props.state.fontSize.value}
					labelValueUnit="px"
					min={props.state.fontSize.min || 10}
					max={props.state.fontSize.max}
					onChange={handleOnChangeFontSize}
					styles={{
						color: "base",
						size: "small",
					}}
				/>
				<Select
					selectedValue={weightValue[props.state.fontWeight.toString() as keyof typeof weightValue]}
					options={Array.from(new Set(props.font.metadata.weightsCode,)).map(w => {
						const weight = w.toString()
						const label = weightValue[weight as keyof typeof weightValue]
						return {
							element: <div className={Styles.weight_option} style={{ fontWeight: w, }}>{label}</div>,
							onClick: () => handleOnChangeWeight(w),
						}
					})}
				/>
				<div className={Styles.wrapper_input_percentage}>
					<InputPercentage
						value={props.state.letterSpacing.value}
						min={props.state.letterSpacing.min}
						max={props.state.letterSpacing.max}
						icon={{ SVGComponent: IconLetterSpacing, }}
						onChange={handleOnChangeLetterSpacing}
						customStyles={{
							inputVariant: "current_color",
						}}
					/>
				</div>

				<div className={Styles.wrapper_input_percentage}>
					<InputPercentage
						value={props.state.lineHeight.value}
						min={props.state.lineHeight.min}
						max={props.state.lineHeight.max}
						icon={{ SVGComponent: IconLineHeight, }}
						onChange={handleOnChangeLineHeight}
						customStyles={{
							inputVariant: "current_color",
						}}
					/>
				</div>
				<ButtonIcon
					type="button"
					onClick={handleOnChangeTextAlign}
					iconSVGComponent={textAlignNextStep[props.state.textAlign].icon}
					customStyles={{
						variant: "ghost",
					}}
				/>
			</div>
		</section>
	)
}

export default PlaygroundToolbar