/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import Styles from "./TextPlayground.module.scss"
import { TextState } from "../../FontsPlaygroundHelper"
import classNames from "classnames"

type Props = {
	children: React.ReactElement,
	textState: TextState,
	selected: boolean,
	onClick: () => void,
	isEditing: boolean,
}

const TextPlayground = (props: Props) => {


	const containerClass = classNames(Styles.text_container, {
		[Styles.selected]: props.selected,
		[Styles.is_editing]: props.isEditing,
	})

	return (
		<div className={containerClass}
			onClick={(e) => {
				if (!props.isEditing) {
					e.stopPropagation()
					props.onClick()
				}
			}}
			style={{
				minHeight: props.textState.fontSize.value,
				fontSize: props.textState.fontSize.value,
				fontWeight: props.textState.fontWeight,
				lineHeight: `${props.textState.lineHeight.value}%`,
				letterSpacing: `${props.textState.letterSpacing.value / 100}em`,
				textAlign: props.textState.textAlign,
			}}>
			{props.children}
		</div>
	)
}

export default TextPlayground
