import { DisplayWidgetType } from "deblank-api-types"
import {
	colorFeedbackOptions, fontsFeedbackOptions, genericFeedbackOptions, imagesFeedbackOptions
} from "../../FeedbackAction/FeedbackOptions"

export const isExplanationActionRestricted = (widgetType: DisplayWidgetType) => {

	// const isExplanationActionRestricted = ([
	// 	DisplayWidgetType.AccessibilityColors,
	// 	DisplayWidgetType.ColorsExplanations,
	// 	DisplayWidgetType.FontsExplanations,
	// 	DisplayWidgetType.Images,
	// 	DisplayWidgetType.Searches,
	// 	DisplayWidgetType.MockupColors,
	// 	DisplayWidgetType.MockupFonts,
	// ]).includes(widgetType)

	// return isExplanationActionRestricted

	//Hide explanation button for all widgets
	return true
}

export const isRegenerateActionRestricted = (widgetType: DisplayWidgetType) => {
	const isRegenerateActionRestricted = ([
		DisplayWidgetType.AccessibilityColors,
		DisplayWidgetType.ColorsExplanations,
		DisplayWidgetType.FontsExplanations,
		DisplayWidgetType.Searches,
		DisplayWidgetType.Brands,
		DisplayWidgetType.Attachment,
	]).includes(widgetType)

	return isRegenerateActionRestricted
}

export const getFeedbackOptions = (type: DisplayWidgetType) => {
	switch (type) {
		case DisplayWidgetType.Colors:
		case DisplayWidgetType.MockupColors:
			return colorFeedbackOptions

		case DisplayWidgetType.Fonts:
		case DisplayWidgetType.MockupFonts:
			return fontsFeedbackOptions

		case DisplayWidgetType.Images:
		case DisplayWidgetType.Searches:
			return imagesFeedbackOptions

		case DisplayWidgetType.ColorsExplanations:
		case DisplayWidgetType.AccessibilityColors:
		case DisplayWidgetType.FontsExplanations:
			return genericFeedbackOptions

		default:
			return genericFeedbackOptions
	}
}
