
import IconTextLeft from "deblank-common/src/assets/images/icon-library/text-left.svg"
import IconTextRight from "deblank-common/src/assets/images/icon-library/text-right.svg"
import IconTextCenter from "deblank-common/src/assets/images/icon-library/text-center.svg"
import IconTextJustify from "deblank-common/src/assets/images/icon-library/text-justify.svg"

export enum TextAlign {
	left = "left",
	center = "center",
	right = "right",
	justify = "justify",
}


export type TextState = {
	fontSize: {
		value: number,
		max: number,
		min: number,
	},
	fontWeight: number,
	lineHeight: {
		value: number,
		max: number,
		min: number,
	},
	letterSpacing: {
		value: number,
		max: number,
		min: number,
	},
	textAlign: TextAlign,
}

export const weightValue = {
	"100": "Thin",
	"200": "Extra Light",
	"300": "Light",
	"400": "Regular",
	"500": "Medium",
	"600": "Semi Bold",
	"700": "Bold",
	"800": "Black",
	"900": "Heavy",
}

export const textAlignNextStep = {
	"justify": {
		nextAlignvalue: TextAlign.left,
		icon: IconTextJustify,
	},
	"left": {
		nextAlignvalue: TextAlign.center,
		icon: IconTextLeft,
	},
	"center": {
		nextAlignvalue: TextAlign.right,
		icon: IconTextCenter,
	},
	"right": {
		nextAlignvalue: TextAlign.justify,
		icon: IconTextRight,
	},
}


//Choose fallback font weight if the selected one is not available
const getFontWeight = (fontWeights: number[]) => {
	if (fontWeights.includes(500)) {
		return 500
	} else {
		return fontWeights[Math.ceil(fontWeights.length / 2) - 1]
	}
}

export const getTitleDefaulState = (fontWeights: number[]) => {
	return {

		fontSize: {
			value: 70,
			max: 200,
			min: 0,
		},
		fontWeight: getFontWeight(fontWeights),
		lineHeight: {
			value: 100,
			max: 130,
			min: 0,
		},
		letterSpacing: {
			value: 0,
			max: 20,
			min: -20,
		},
		textAlign: TextAlign.left,
	}
}

export const getSubtitleDefaulState = (fontWeights: number[]) => {
	return {
		fontSize: {
			value: 35,
			max: 100,
			min: 0,
		},
		fontWeight: getFontWeight(fontWeights),
		lineHeight: {
			value: 130,
			max: 140,
			min: 40,
		},
		letterSpacing: {
			value: 0,
			max: 20,
			min: -20,
		},
		textAlign: TextAlign.left,
	}
}

export const getBodyDefaulState = (fontWeights: number[]) => {
	return {
		fontSize: {
			value: 16,
			max: 40,
			min: 0,
		},
		fontWeight: getFontWeight(fontWeights),
		lineHeight: {
			value: 180,
			max: 200,
			min: 40,
		},
		letterSpacing: {
			value: 0,
			max: 20,
			min: -20,
		},
		textAlign: TextAlign.left,
	}
}
