import { Variants, HTMLMotionProps } from "framer-motion"

const playgroundVariants: Variants = {
	hidden: {
		y: "100%",
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
}

const playgroundTransition: HTMLMotionProps<"div">["transition"] = {
	type: "tween",
	duration: 0.15,
	ease: [0.4, 0.14, 0.3, 1,],
}


export const playgroundMotionProps = {
	variants: playgroundVariants,
	initial: "hidden",
	animate: "visible",
	exit: "hidden",
	transition: playgroundTransition,
}
