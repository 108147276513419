import React, { useContext, useEffect, useRef, useState } from "react"
import Styles from "./PinnedList.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import DownloadIcon from "deblank-common/src/assets/images/icon-library/download.svg"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { useRecoilValue } from "recoil"
import PinnedListEmptyMessage from "./PinnedListEmptyMessage/PinnedListEmptyMessage"
import { AnimatePresence, m } from "framer-motion"
import { containerMotionProps, widgetMotionProps } from "./Animations"
import PinWidgetSelector from "./PinWidgetSelector/PinWidgetSelector"
import Tooltip from "@/components/Tooltip/Tooltip"
import {
	DisplayWidgetType, EntryImagesResult, EntryMockupColorsResult, EntryMockupFontsResult, EntrySearchesResult, SearchFrom
} from "deblank-api-types"
import { DownloadHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { TrackConversationEventContext } from "../../TrackConversationEventProvider"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { selectorsPinned } from "@/recoil/ConversationsRecord/Selectors/Pinned"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"
import ImageManager from "@/utils/ImageManager"


// eslint-disable-next-line max-lines-per-function
const PinnedList = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [pinListLength, setPinListLength,] = useState<number>(0)
	const pinnedContainerRef = useRef<null | HTMLDivElement>(null)

	const togglePinned = settersUiTemporary.useToggleIsOpenPinned()
	const pinList = useRecoilValue(selectorsPinned.pinListInCurrentConversation)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const outputList = useRecoilValue(selectorsWidgets.getOutputListInCurrentConversation)
	const outputPinList = useRecoilValue(selectorsPinned.pinListOutputsInCurrentConversation)

	const handleCloseSidebar = () => { togglePinned() }

	const handleDownload = async () => {
		const urlList: string[] = []
		const promises = pinList!.map(async (widgetIdRef) => {

			const currentWidget = outputList![widgetIdRef.widgetId]
			const type = outputList![widgetIdRef.widgetId].type

			switch (type) {
				case DisplayWidgetType.Searches: {
					const widgetItem = currentWidget as EntrySearchesResult
					if (widgetItem.data.results[widgetIdRef.widgetItemIndex].searchFrom === SearchFrom.Freeflo) {
						urlList.push(widgetItem.data.results[widgetIdRef.widgetItemIndex].imageUrl)
					}
					break
				}
				case DisplayWidgetType.Images: {
					const widgetItem = currentWidget as EntryImagesResult
					const imageFileName = widgetItem.data.pages[widgetIdRef.widgetPageIndex].results[widgetIdRef.widgetItemIndex]
						.imageFileName
					const getSignedUrl = await ImageManager.getImageUrl({
						imageFileName: imageFileName,
						conversationId: activeConversationId!,
					})

					const signedUrl = await getSignedUrl.signedUrl
					urlList.push(signedUrl)
					break
				}
				case DisplayWidgetType.MockupColors:
				case DisplayWidgetType.MockupFonts: {
					const widget = outputList![widgetIdRef.widgetId] as EntryMockupFontsResult | EntryMockupColorsResult
					const imageFileName = widget.data.pages[widgetIdRef.widgetPageIndex].results[widgetIdRef.widgetItemIndex]
						.imageFileName
					const getSignedUrl = await ImageManager.getImageUrl({
						imageFileName: imageFileName,
						conversationId: activeConversationId!,
					})

					const signedUrl = await getSignedUrl.signedUrl
					urlList.push(signedUrl)
					break
				}
				case DisplayWidgetType.Colors:
				case DisplayWidgetType.Fonts: {
					const widgetIdDom = ScreenshotIdManager.getElementFromDocument({
						widgetUiSection: WidgetUiSectionId.Pin,
						idRef: widgetIdRef,
					})!
					const widgetFromDom = await DownloadHelper.urlFromTemplate(widgetIdDom)
					urlList.push(widgetFromDom)
					break
				}
				case DisplayWidgetType.Brands: {
					const widgetIdDom = ScreenshotIdManager.getElementFromDocument({
						widgetUiSection: WidgetUiSectionId.Pin,
						idRef: widgetIdRef,
					})!
					const widgetFromDom = await DownloadHelper.urlFromTemplate(widgetIdDom)
					urlList.push(widgetFromDom)
					break
				}
				case DisplayWidgetType.AccessibilityColors:
				case DisplayWidgetType.ColorsExplanations:
				case DisplayWidgetType.FontsExplanations:
				case DisplayWidgetType.Text:
				case DisplayWidgetType.Question:
				case DisplayWidgetType.Attachment:
					console.error(`${type} is not downloadable item`)
					break

				default: {
					const _exhaustiveCheck: never = type
					const errorMessage = `Unknown widget for export: ${_exhaustiveCheck}`
					console.error(errorMessage)
				}
			}
		})

		await Promise.all(promises || [])
		if (urlList.length > 0) {
			await DownloadHelper.zip(urlList)
			trackConversationEvent({
				eventName: "DownloadAll",
				widgetTypes: outputPinList!.map((widget) => widget.type),
			})
		}
	}

	useEffect(() => {
		if (pinList
			&& pinList.length > 3
			&& pinListLength < pinList.length
		) {
			pinnedContainerRef.current!.scrollTo({
				top: pinnedContainerRef.current!.scrollHeight,
				behavior: "smooth",
			})
		}

		setPinListLength(pinList ? pinList.length : 0)
	}, [
		pinList,
	])

	return (
		<section className={Styles.container}>
			<article className={Styles.top_actions}>
				<p className={Styles.top_actions_title}>
					Pinned inspiration
				</p>
				<div className={Styles.actions_container}>
					{
						pinList && <>
							<Tooltip tooltipContent="Download"
								customStyles={{
									position: "bottom",
								}}>
								<ButtonIcon
									type="button"
									onClick={handleDownload}
									iconSVGComponent={DownloadIcon}
									customStyles={{
										variant: "ghost",
									}}
								/>
							</Tooltip>
							<span className={Styles.separator} />
						</>
					}
					<ButtonIcon
						type="button"
						onClick={handleCloseSidebar}
						iconSVGComponent={CrossIcon}
						customStyles={{
							variant: "ghost",
						}}
					/>
				</div>
			</article>
			<section className={Styles.content_pinned} ref={pinnedContainerRef} key={activeConversationId}>
				<AnimatePresence mode="popLayout">
					{pinList
						? <m.section key="pinned_list"
							className={Styles.container_widgets}
							{...containerMotionProps}>
							<AnimatePresence mode="popLayout">
								{pinList.map((pinItem, index) => {
									return (
										<m.article key={`${activeConversationId}-${pinItem.messageId}-${index}`}
											className={Styles.widget_animation}
											{...widgetMotionProps}>
											<PinWidgetSelector
												idRef={pinItem}
											/>
										</m.article>
									)
								})}
							</AnimatePresence>
						</m.section>
						: <m.article key="empty_message"
							className={Styles.container_empty_message}
							{...containerMotionProps}>
							<PinnedListEmptyMessage />
						</m.article>
					}
				</AnimatePresence>
			</section>
		</section>
	)
}

export default PinnedList
