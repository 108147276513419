import React from "react"
import {
	EntryColorsExplanationResult, IdRefType,
} from "deblank-api-types"
import { CommonWidgetColorProps } from "../WidgetColorsTypes"
import ExplanationCard from "./ExplanationCard/ExplanationCard"


type Props = {
	widgetDetails: EntryColorsExplanationResult,
} & Pick<CommonWidgetColorProps, "idRef">

const WidgetExplanationColors = (props: Props) => {
	return (
		props.widgetDetails.data.results.map((widgetDetails, index) => (
			<ExplanationCard
				key={widgetDetails.id}
				type={props.widgetDetails.type}
				widgetData={widgetDetails}
				idRef={{
					type: IdRefType.WidgetWithPaginationItem,
					messageId: props.idRef.messageId,
					widgetId: props.idRef.widgetId,
					widgetPageIndex: 0,
					widgetItemIndex: index,
					widgetItemId: widgetDetails.id,
				}}
			/>
		))
	)
}

export default WidgetExplanationColors
