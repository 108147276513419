import { EntryColorsResponse, FontResponse, ImageResponse, SearchResponse } from "deblank-api-types"
import { CustomSelectValue, InputSelectValue, } from "../RelatedActionsTypes"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"
import { MockupsSelectValue } from "@/components/WidgetsByType/Mockups/WidgetMockupsTypes"

// Check if all properties from value are falsy or if array is empty
export const valueIsEmpty = (value: EntryColorsResponse | FontResponse
	| ImageResponse | SearchResponse | CustomSelectValue | InputSelectValue | ImageSelectValue[] | MockupsSelectValue
) => {
	return !Object.values(value).some(val => {
		if (Array.isArray(val)) {
			return val.length > 0
		}
		return Boolean(val)
	})
}
