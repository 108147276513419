import { useEffect, useState } from "react"
import { DisplayWidgetType, EntryColorsResponse, } from "deblank-api-types"
import { trpc } from "@/providers/TRPCProvider"

/**
 * `data-testid` attributes have
 * out-of-the-box support in Playwright
 */

// Generates the full `data-testid` attr
const testIdAttribute = (id: string) => ({ "data-testid": `t_${id}`, })

// Generates the ID to be used within `data-testid`
const testIdString = (id: string) => `t_${id}`

/**
 * Metadata varies depending on the output type
 */
const outputMetadata = {
	text: (d: {
		title?: string,
		messageId?: string,
		message: string,
	}) => {
		return {
			"data-t-id": undefined,
			"data-t-output": JSON.stringify(d),
		}
	},
	font: (
		d: {
			id: string,
			messageId: string,
			slug: string,
		}) => {
		return {
			"data-t-id": d.id,
			"data-t-output": JSON.stringify(d),
		}
	},
	fontExplanation: (
		d: {
			font: string,
			explanation: {
				characteristics: string,
				usage: string,
			},
		}) => {
		return {
			"data-t-id": undefined,
			"data-t-output": JSON.stringify(d),
		}
	},
	colors: (d: EntryColorsResponse & {
		messageId: string,
	}) => {
		return {
			"data-t-id": d.id,
			"data-t-output": JSON.stringify({
				id: d.id,
				colors: d.palette,
				messageId: d.messageId,
			}),
		}
	},
	colorsExplanation: (d: {
		colors: string,
		explanation: string,
	}) => {
		return {
			"data-t-id": undefined,
			"data-t-output": JSON.stringify(d),
		}
	},
	image: (d: {
		id: string,
		messageId: string,
		promptRefinedByAgent: string,
	}) => {
		return {
			"data-t-id": d.id,
			"data-t-output": JSON.stringify({
				id: d.id,
				messageId: d.messageId,
				promptRefinedByAgent: d.promptRefinedByAgent,
			}),
		}
	},
	mockup: (d: {
		type: DisplayWidgetType,
		mockupId: string,
		messageId: string,
	}) => {
		return {
			"data-t-id": undefined,
			"data-t-output": JSON.stringify(d),
		}
	},
}

/**
 * Custom hook that handles the inclusion of test helpers
 */
export const useTestHelper = () => {
	const { data, } = trpc.testing.isTestingUser.useQuery()
	const [isTestingUser, setIsTestingUser,] = useState<boolean>(Boolean(data))

	useEffect(() => {
		setIsTestingUser(Boolean(data))
	}, [data,])

	return isTestingUser ? {
		outputMetadata: outputMetadata,
		loadingMessage: testIdAttribute("loading_message"),
		promptInput: testIdAttribute("prompt_input"),
		debugMetadata: testIdAttribute("debug_metadata"),
		submitButton: testIdString("submit_button"),
		skipQuestions: testIdString("skip_questions"),
		hideOnScreenshot: { "data-t-hide-on-screenshot": "true", },
	} : null
}
