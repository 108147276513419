import React from "react"
import Styles from "./DrawerWrapper.module.scss"
import { m } from "framer-motion"
import { usePreventScroll } from "deblank-common/src/hooks/usePreventScroll"
import CrossIcon from "deblank-common/src/assets/images/icon-library/x.svg"
import ButtonIcon from "../Buttons/ButtonIcon/ButtonIcon"

type Props = {
	children: React.ReactNode,
	title: string,
	onClose: () => void,
}

const DrawerWrapper = (props: Props) => {
	usePreventScroll({ prevent: true, })

	return (
		<section className={Styles.drawer_wrapper}>
			<m.span className={Styles.overlay}
				initial={{ opacity: 0, }}
				animate={{ opacity: 1, }}
				exit={{ opacity: 0, }}
			/>
			<m.div className={Styles.drawer}
				initial={{ y: "100%", }}
				animate={{ y: "0%", }}
				exit={{ y: "100%", }}
				transition={{ ease: "easeOut", duration: 0.1, }}
			>
				<div className={Styles.drawer_header}>
					<p>{props.title}</p>
					<ButtonIcon
						customStyles={{ variant: "ghost", }}
						type="button"
						onClick={props.onClose}
						iconSVGComponent={CrossIcon}
					/>
				</div>
				{props.children}
			</m.div>
		</section>
	)
}

export default DrawerWrapper
