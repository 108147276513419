import React from "react"
import { useRecoilValue } from "recoil"
import { selectorsAssistantResponseTools } from "@/recoil/ConversationsRecord/Selectors/AssistantResponseTools"
import Styles from "./DebugInfoForTest.module.scss"
import { useTestHelper } from "@/hooks/useTestHelper"

const DebugInfoForTest = () => {
	const testHelper = useTestHelper()
	const debugContent = useRecoilValue(selectorsAssistantResponseTools.debugInCurrentConversation)

	// TODO: check cost and duration calculation

	const totalCost = debugContent?.reduce((acc, curr) => {
		return parseFloat((acc + curr.cost).toFixed(3))
	}, 0)

	const totalDuration = debugContent?.reduce((acc, curr) => {
		return parseFloat((acc + curr.duration).toFixed(3))
	}, 0)

	return testHelper ? (
		<div
			className={Styles.wrapper}
			{...testHelper.debugMetadata}
		>
			{JSON.stringify({
				"totalCost": totalCost ?? 0,
				"totalDuration": totalDuration ?? 0,
			})}
		</div>
	) : null
}

export default DebugInfoForTest
