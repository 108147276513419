/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { forwardRef } from "react"
import TextPlayground from "./TextPlayground/TextPlayground"
import Styles from "./FontsPlaygroundMobile.module.scss"
import { SanitizedElementProps } from "deblank-common/src/hooks/useSanitizedState"
import { Pairing } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { TextState } from "../FontsPlaygroundHelper"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import PlaygroundToolbar from "./PlaygroundToolbar/PlaygroundToolbar"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"
import ContentEditable from "react-contenteditable"

type Props = {
	body: {
		elementProps: SanitizedElementProps,
		onBlur: (e: React.FocusEvent<HTMLElement>) => void,
		onChange: (value: string) => void,
		textState: TextState,
		onChangeTextState: React.Dispatch<React.SetStateAction<TextState>>,
		value: string,
	},
	subtitle: {
		elementProps: SanitizedElementProps,
		onBlur: (e: React.FocusEvent<HTMLElement>) => void,
		onChange: (value: string) => void,
		textState: TextState,
		onChangeTextState: React.Dispatch<React.SetStateAction<TextState>>,
		value: string,
	},
	title: {
		elementProps: SanitizedElementProps,
		onBlur: (e: React.FocusEvent<HTMLElement>) => void,
		onChange: (value: string) => void,
		textState: TextState,
		onChangeTextState: React.Dispatch<React.SetStateAction<TextState>>,
		value: string,
	},
}

export enum PlaygroundMode {
	FontWeight = "fontWeight",
	FontSize = "fontSize",
	Align = "align",
	TextEdit = "textEdit",
}

export enum TextType {
	Title = "title",
	Subtitle = "subtitle",
	Body = "body",
}

const FontsPlaygroundMobile = forwardRef((
	props: Props,
	ref: React.ForwardedRef<HTMLDivElement>,
) => {
	const widget = useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const widgetSelectData = useMemo(() => (widget), [])
	const fontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.widgetData.slug))
	const baseFontDetails = useRecoilValue(selectorsFontCache.fontFromCache(widgetSelectData?.metadata.baseFont || undefined))
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const titleRef = useRef<HTMLElement>(null)
	const subtitleRef = useRef<HTMLElement>(null)
	const bodyRef = useRef<HTMLElement>(null)

	const [selected, setSelected,] = useState<TextType | null>(TextType.Title)
	const [selectedMode, setSelectedMode,] = useState<PlaygroundMode | null>(null)

	const getSelectedTextData = useCallback((textType: TextType) => {
		let font
		let ref
		let selectedProps

		switch (textType) {
			case TextType.Title:
				font = widgetSelectData!.metadata.pairing === Pairing.paragraph
					? baseFontDetails!
					: fontDetails!
				ref = titleRef.current

				selectedProps = props.title
				break

			case TextType.Subtitle:
				font = widgetSelectData!.metadata.pairing === Pairing.title
					? baseFontDetails!
					: fontDetails!
				ref = subtitleRef.current
				selectedProps = props.subtitle
				break
			case TextType.Body:
				font = widgetSelectData!.metadata.pairing === Pairing.title
					? baseFontDetails!
					: fontDetails!
				ref = bodyRef.current
				selectedProps = props.body
				break
			default:
				font = fontDetails!
				ref = null
				selectedProps = null
				break
		}
		return {
			font,
			ref,
			...selectedProps,
		}
	}, [props.body, props.subtitle, props.title,])


	useEffect(() => {
		if (selectedMode === PlaygroundMode.TextEdit) {
			const selectedData = getSelectedTextData(selected!)
			selectedData.ref!.focus()

			setTimeout(() => {
				const range = document.createRange()
				const sel = window.getSelection()
				range.setStart(selectedData.ref!.childNodes[0], selectedData.value!.length)
				range.collapse(true)
				sel?.removeAllRanges()
				sel?.addRange(range)
				selectedData.ref!.focus()

			}, 0)
		}
	}, [selectedMode, selected,])


	if (!widgetSelectData) {
		return null
	}


	const handleSelect = (textType: TextType) => {
		if (selected === textType) {
			setSelectedMode(PlaygroundMode.TextEdit)
		} else {
			setSelected(textType)
			setSelectedMode(null)
		}

	}

	const contentClass = classNames(Styles.content, {
		[Styles.open_toolbar]: selected,
	})

	return (
		<>
			<div className={contentClass} ref={ref} onClick={() => {
				setSelected(null)
				setSelectedMode(null)
			}}>

				<TextPlayground
					textState={props.title.textState}
					selected={selected === TextType.Title}
					onClick={() => handleSelect(TextType.Title)}
					isEditing={selectedMode === PlaygroundMode.TextEdit && selected === TextType.Title}
				>
					<ContentEditable
						innerRef={titleRef}
						className={`${Styles.extend_styles} ${Styles.editable_text}`}
						style={widgetSelectData.metadata.pairing === Pairing.paragraph
							? baseFontDetails!.fontCSSProperties
							: fontDetails!.fontCSSProperties}
						html={props.title.elementProps.dangerouslySetInnerHTML.__html}
						disabled={selectedMode !== PlaygroundMode.TextEdit || selected !== TextType.Title}
						onClick={e => {
							if (selectedMode === PlaygroundMode.TextEdit && selected === TextType.Title) {
								e.stopPropagation()
							}
						}}
						onBlur={() => {
							trackConversationEvent({
								eventName: "ChangeText",
								textType: "title",
								fontSlug: widgetSelectData.widgetData.slug,
							})
						}}
						spellCheck={false}
						onChange={(e) => props.title.onChange(e.target.value)}

					/>
				</TextPlayground>

				<TextPlayground
					textState={props.subtitle.textState}
					selected={selected === TextType.Subtitle}
					onClick={() => handleSelect(TextType.Subtitle)}
					isEditing={selectedMode === PlaygroundMode.TextEdit && selected === TextType.Subtitle}
				>
					<ContentEditable
						innerRef={subtitleRef}
						className={`${Styles.extend_styles} ${Styles.editable_text}`}
						style={widgetSelectData.metadata.pairing === Pairing.title
							? baseFontDetails!.fontCSSProperties
							: fontDetails!.fontCSSProperties}
						html={props.subtitle.elementProps.dangerouslySetInnerHTML.__html}
						disabled={selectedMode !== PlaygroundMode.TextEdit || selected !== TextType.Subtitle}
						onClick={e => {
							if (selectedMode === PlaygroundMode.TextEdit && selected === TextType.Subtitle) {
								e.stopPropagation()
							}
						}}
						onBlur={() => {
							trackConversationEvent({
								eventName: "ChangeText",
								textType: "subtitle",
								fontSlug: widgetSelectData.widgetData.slug,
							})
						}}
						spellCheck={false}
						onChange={(e) => props.subtitle.onChange(e.target.value)}
					/>
				</TextPlayground>

				<TextPlayground
					textState={props.body.textState}
					selected={selected === TextType.Body}
					onClick={() => handleSelect(TextType.Body)}
					isEditing={selectedMode === PlaygroundMode.TextEdit && selected === TextType.Body}
				>
					<ContentEditable
						innerRef={bodyRef}
						className={`${Styles.extend_styles} ${Styles.editable_text}`}
						style={widgetSelectData.metadata.pairing === Pairing.title
							? baseFontDetails!.fontCSSProperties
							: fontDetails!.fontCSSProperties}
						html={props.body.elementProps.dangerouslySetInnerHTML.__html}
						disabled={selectedMode !== PlaygroundMode.TextEdit || selected !== TextType.Body}
						onClick={e => {
							if (selectedMode === PlaygroundMode.TextEdit && selected === TextType.Body) {
								e.stopPropagation()
							}
						}}
						onBlur={() => {
							trackConversationEvent({
								eventName: "ChangeText",
								textType: "body",
								fontSlug: widgetSelectData.widgetData.slug,
							})
						}}
						spellCheck={false}
						onChange={(e) => props.body.onChange(e.target.value)}
					/>
				</TextPlayground>
			</div>

			<AnimatePresence>
				{selected && <PlaygroundToolbar
					selectedPlaygroundMode={selectedMode}
					messageId={widgetSelectData.idRef.messageId}
					font={getSelectedTextData(selected!).font}
					onChangeTextState={getSelectedTextData(selected!).onChangeTextState!}
					textState={getSelectedTextData(selected!).textState!}
					onEditAlign={() => setSelectedMode(PlaygroundMode.Align)}
					onEditFontWeight={() => setSelectedMode(PlaygroundMode.FontWeight)}
					onEditSize={() => setSelectedMode(PlaygroundMode.FontSize)}
					onEditText={() => setSelectedMode(PlaygroundMode.TextEdit)}
					onCloseMode={() => setSelectedMode(null)}
					onClosePlayground={() => {
						setSelected(null)
						setSelectedMode(null)
					}}
				/>}
			</AnimatePresence>
		</>
	)
})

FontsPlaygroundMobile.displayName = "FontsPlaygroundMobile"

export default FontsPlaygroundMobile
