import React, { useContext } from "react"
import Styles from "./FontExplanation.module.scss"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DisplayWidgetType, FontExplanationsResponse, IdRefWidgetWithPaginationItem } from "deblank-api-types"
import Markdown from "marked-react"
import { useTestHelper } from "@/hooks/useTestHelper"

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	type: DisplayWidgetType,
	widgetData: FontExplanationsResponse,
}

const FontExplanation = (props: Props) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const testHelper = useTestHelper()

	const fontDetails = useRecoilValue(selectorsFontCache.fontFromCache(props.widgetData.slug))
	// Ensures proper markdown formatting for numbered lists by adding a newline before each item.
	const usageMarkdown = props.widgetData.explanation.usage.replace(
		/(\d+)\.\s/g,
		"\n$1. "
	)

	const characteristicsMarkdown = props.widgetData.explanation.characteristics.replace(
		/(\d+)\.\s/g,
		"\n$1. "
	)

	const explanationMarkdown = `
**Characteristics:** ${characteristicsMarkdown}

**Usage:** ${usageMarkdown}
  `

	return (
		<article
			className={`${Styles.wrapper}`}
			{...testHelper?.outputMetadata.fontExplanation({
				font: fontDetails?.metadata.name ?? "",
				explanation: {
					characteristics: props.widgetData.explanation.characteristics,
					usage: props.widgetData.explanation.usage,
				},
			})}
		>
			<div className={Styles.header}>
				<h3 className={Styles.fonts_name}>
					{fontDetails?.metadata.name}
				</h3>

				<a
					className={Styles.link_vendor}
					href={fontDetails?.metadata.linkToVendor}
					target="_blank"
					rel="noreferrer"
					onClick={async () => {
						await trackConversationEvent({
							eventName: "ClickGetFont",
							fontName: fontDetails!.metadata.name,
							responseId: props.idRef.messageId,
						})
					}}
				>
					<LinkIcon />
				</a>
			</div>

			<div className={`${Styles.content_explanations} custom_markdown`}>
				<Markdown>{explanationMarkdown}</Markdown>
			</div>
		</article>
	)
}

export default FontExplanation
