import React from "react"
import Styles from "./TextPlayground.module.scss"
import { TextState } from "../../FontsPlaygroundHelper"
import { FontFromCache } from "@/recoil/FontCache/Types"
import PlaygroundToolbar from "./PlaygroundToolbar/PlaygroundToolbar"

type Props = {
	children: React.ReactElement,
	font: FontFromCache,
	messageId: string,
	textState: TextState,
	onChange: React.Dispatch<React.SetStateAction<TextState>>,
}

const TextPlayground = (props: Props) => {

	return (
		<div className={Styles.wrapper}>
			<PlaygroundToolbar
				state={props.textState}
				font={props.font}
				onChange={props.onChange}
				messageId={props.messageId}
			/>
			<div className={Styles.text_container}
				style={{
					minHeight: props.textState.fontSize.value,
					fontSize: props.textState.fontSize.value,
					fontWeight: props.textState.fontWeight,
					lineHeight: `${props.textState.lineHeight.value}%`,
					letterSpacing: `${props.textState.letterSpacing.value / 100}em`,
					textAlign: props.textState.textAlign,
				}}>
				{props.children}
			</div>
			<div className={Styles.divider} />
		</div>
	)
}

export default TextPlayground
