import React, { useContext, } from "react"
import Styles from "./ExplanationCard.module.scss"
import CopyIcon from "deblank-common/src/assets/images/icons/copy.svg"
import CheckIcon from "deblank-common/src/assets/images/icons/check.svg"
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ColorUtils } from "@/utils/ColorUtils"
import { IdRefWidgetWithPaginationItem, EntryColorsExplanationResponse, DisplayWidgetType } from "deblank-api-types"
import Markdown from "marked-react"
import { useTestHelper } from "@/hooks/useTestHelper"


type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	widgetData: EntryColorsExplanationResponse,
	type: DisplayWidgetType,
}

const MarkdownWithColors = ({ markdown, }: { markdown: string, }) => {
	const hexColorRegex = /(#[A-Fa-f0-9]{6}|#[A-Fa-f0-9]{3})/g

	const renderers = {
		text: (text: string) => {
			const parts = text.split(hexColorRegex)

			return (
				<>
					{parts.map((part, index) =>
					(hexColorRegex.test(part) ? (
						<span
							key={index}
							className={Styles.hexa_code}
						>
							<span
								className={Styles.hexa_color_block}
								style={{
									backgroundColor: part,
								}}
							/>
							{part}
						</span>
					) : (
						part
					))
					)}
				</>
			)
		},
	}

	return (
		<Markdown value={markdown} renderer={renderers} />
	)
}


const ExplanationCard = (props: Props) => {

	const { copyToClipboard, contentCopied, } = useCopyToClipboard()
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const testHelper = useTestHelper()

	function handleCopyToClipboard(color: string, id: string) {
		trackConversationEvent({
			eventName: "CopyColor",
			colorCode: color,
		})
		copyToClipboard({ text: color, id: id, })
	}

	return (
		<div
			className={Styles.wrapper}
			{...testHelper?.outputMetadata.colorsExplanation({
				colors: props.widgetData.palette.join(", "),
				explanation: props.widgetData.explanation,
			})}
		>
			<article className={Styles.container}>
				<div className={Styles.palette}>
					{props.widgetData.palette.map((color, index) => (
						<div
							key={index}
							className={Styles.color_item}
							style={{ "--current_color": color, } as React.CSSProperties}
						>
							<button
								type="button"
								className={Styles.copy_button}
								onClick={() => handleCopyToClipboard(color, index.toString())}
								style={{ "--fg_color": ColorUtils.getForegroundColor(color), } as React.CSSProperties}
							>
								{contentCopied === index.toString()
									? <CheckIcon />
									: <CopyIcon />
								}
							</button>
						</div>
					))}

				</div>

				<div className={`${Styles.explanation} custom_markdown`}>
					<MarkdownWithColors markdown={props.widgetData.explanation} />
				</div>
			</article>

		</div>
	)
}

export default ExplanationCard
