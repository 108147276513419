import { ColorRelatedActions } from "./ColorRelated/colorRelatedActions"
import { FontRelatedActions } from "./FontsRelated/fontRelatedActions"
import { ImageRelatedActions } from "./ImagesRelated/imageRelatedActions"
import { RelatedActionData } from "./RelatedActionsTypes"
import { SearchRelatedActions } from "./SearchesRelated/searchRelatedActions"
import { FontPairingRelatedActions } from "./FontsRelated/fontRelatedActions"
import { MockupsRelatedActions } from "./MockupsRelated/MockupsRelatedActions"

export const RelatedActions = {
	...ColorRelatedActions,
	...FontRelatedActions,
	...ImageRelatedActions,
	...FontPairingRelatedActions,
	...SearchRelatedActions,
	...MockupsRelatedActions,
}

export type RelatedActionsId = keyof typeof RelatedActions

export type RelatedActionsType = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key in RelatedActionsId]: RelatedActionData<any>
}
