import { useSanitizedState } from "deblank-common/src/hooks/useSanitizedState"

export const useSanitizedStates = (title: string, subtitle: string, body: string) => {
	const [setInputTitleValue, titleProps, titleValue,] = useSanitizedState(title)
	const [setInputSubtitleValue, subtitleProps, subtitleValue,] = useSanitizedState(subtitle)
	const [setInputBodyValue, bodyProps, bodyValue,] = useSanitizedState(body)

	const handleTitleOnBlur = (e: React.FocusEvent<HTMLElement>) => {
		titleProps.onBlur(e)
	}

	const handleTitleOnChange = (value: string) => {
		titleProps.onChange(value)
	}


	const handleSubtitleOnBlur = (e: React.FocusEvent<HTMLElement>) => {
		subtitleProps.onBlur(e)
	}

	const handleSubtitleOnChange = (value: string) => {
		subtitleProps.onChange(value)
	}

	const handleBodyOnBlur = (e: React.FocusEvent<HTMLElement>) => {
		bodyProps.onBlur(e)
	}

	const handleBodyOnChange = (value: string) => {
		bodyProps.onChange(value)
	}



	return {
		handleBodyOnBlur,
		handleBodyOnChange,
		handleTitleOnBlur,
		handleTitleOnChange,
		handleSubtitleOnBlur,
		handleSubtitleOnChange,
		setInputTitleValue,
		setInputSubtitleValue,
		setInputBodyValue,
		titleProps,
		subtitleProps,
		bodyProps,
		titleValue,
		subtitleValue,
		bodyValue,
	}

}
