import React, { useRef, useState } from "react"
import * as Sentry from "@sentry/react"
import CustomSelect from "./CustomSelect/CustomSelect"
import InputSelect from "./InputSelect/InputSelect"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import ArrowRightIcon from "deblank-common/src/assets/images/icon-library/arrow-right.svg"
import AirplaneIcon from "deblank-common/src/assets/images/icon-library/send.svg"
import InfoIcon from "deblank-common/src/assets/images/icon-library/info.svg"
import Styles from "./Select.module.scss"
import Button from "@/components/Buttons/Button/Button"
import {
	CustomSelectValue, RelatedActionConfigStep, SelectType, SelectValue
} from "../../../../RelatedActionsConfigs/RelatedActionsTypes"
import { animate, motion, useMotionValue, } from "framer-motion"
import classNames from "classnames"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import { FontSelectValue } from "@/components/WidgetsByType/Fonts/WidgetFontsTypes"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"
import { WidgetColors } from "@/components/WidgetsByType/Colors/WidgetColors"
import { WidgetFonts } from "@/components/WidgetsByType/Fonts/WidgetFonts"
import { WidgetImages } from "@/components/WidgetsByType/Images/WidgetImages"
import { IdRefWidget } from "deblank-api-types"
import { WidgetMockups } from "@/components/WidgetsByType/Mockups/WidgetMockups"
import { MockupsSelectValue } from "@/components/WidgetsByType/Mockups/WidgetMockupsTypes"

type Props<T,> = {
	relatedActionTitle: string,
	stepConfig: RelatedActionConfigStep<T>,
	isLastStep: boolean,
	value: SelectValue,
	isLoading: boolean,
	idRef: IdRefWidget,
	onSelect: (value: SelectValue,) => void,
	nextAction: {
		onNextAction: () => void,
		isDisabled?: boolean,
	},
	isBeta?: boolean,
}

// Based on this example: https://codesandbox.io/s/framer-motion-usescroll-element-scrolling-forked-3n931n?file=/src/App.tsx
const DraggableSelectOptions = (props: {
	children: React.ReactNode,
	setUserCanSelect: (value: boolean) => void,
}) => {
	const optionListRef = useRef<HTMLUListElement>(null)

	const [startX, setStartX,] = useState(0)
	const [scrollLeft, setScrollLeft,] = useState(0)
	const [trackMouse, setTrackMouse,] = useState(false)
	const [animationComplete, setAnimationComplete,] = useState(true)

	const x = useMotionValue(0)

	const handleMouseMove = (e: React.PointerEvent<HTMLUListElement>) => {
		if (!trackMouse) { return }

		setAnimationComplete(false)
		props.setUserCanSelect(false)

		const xVal = e.pageX - optionListRef.current!.offsetLeft
		const walk = (xVal - startX) * 2

		const controls = animate(x, scrollLeft - walk, {
			type: "tween",
			ease: "easeOut",
			duration: 0.0,
			onUpdate: (val) => {
				optionListRef.current!.scrollLeft = val
			},
			onComplete: () => {
				setAnimationComplete(true)
				props.setUserCanSelect(true)
			},
			onStop: () => {
				setAnimationComplete(true)
				props.setUserCanSelect(true)
			},
		})
		return controls.stop
	}

	const handleMouseDown = (e: React.PointerEvent<HTMLUListElement>) => {

		setTrackMouse(true)

		const startX = e.pageX - optionListRef.current!.offsetLeft
		setStartX(startX)

		const scrollLeft = optionListRef.current!.scrollLeft
		setScrollLeft(scrollLeft)
	}

	const handleMouseLeave = () => {
		setTrackMouse(false)
	}

	const handleMouseUp = () => {
		setTrackMouse(false)
	}

	const handleScroll = () => {

		if (animationComplete) {
			x.set(optionListRef.current!.scrollLeft)
		}
	}

	return (
		<motion.ul className={`${Styles.option_list} hide_scrollbar`}
			ref={optionListRef}
			onMouseMove={handleMouseMove}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseLeave}
			onScroll={handleScroll}
		>
			{props.children}
		</motion.ul>
	)

}

const SelectOptions = (props: { children: React.ReactNode, twoColumnsMobile: boolean, }) => {

	const optionListClasses = classNames({
		[Styles.option_list]: true,
		"hide_scrollbar": true,
		[Styles.two_columns_mobile]: props.twoColumnsMobile,
	})

	return (
		<ul className={optionListClasses}>
			{props.children}
		</ul>
	)

}


const Select = <T,>(props: Props<T>) => {
	const [userCanSelect, setUserCanSelect,] = useState(true)

	const handleSelect = (value: SelectValue) => {
		if (userCanSelect) {
			props.onSelect(value)
		}
	}

	const renderSelectType = () => {
		switch (props.stepConfig.selectType) {
			case SelectType.colors:
				return <WidgetColors.RelatedAction
					value={props.value as ColorSelectValue}
					onSelect={handleSelect}
					idRef={props.idRef}
					onNextAction={props.nextAction.onNextAction}
				/>
			case SelectType.fonts:
				return <WidgetFonts.RelatedAction
					value={props.value as FontSelectValue}
					onSelect={handleSelect}
					idRef={props.idRef}
					onNextAction={props.nextAction.onNextAction}
				/>
			case SelectType.custom:
				return <CustomSelect
					value={props.value as CustomSelectValue}
					onSelect={handleSelect}
					options={props.stepConfig.options}
				/>
			case SelectType.images:
				return <WidgetImages.RelatedAction
					value={props.value as ImageSelectValue}
					onSelect={handleSelect}
					idRef={props.idRef}
					onNextAction={props.nextAction.onNextAction}
				/>
			case SelectType.input:
				return <InputSelect
					placeholder={props.stepConfig.placeholder}
					onSelect={handleSelect}
				/>
			case SelectType.imagesMultiSelect:
				return <WidgetImages.RelatedActionMultiSelect
					value={props.value as ImageSelectValue[]}
					onSelect={handleSelect}
					onNextAction={props.nextAction.onNextAction}
					idRef={props.idRef}
				/>
			case SelectType.mockups:
				return <WidgetMockups.RelatedAction
					value={props.value as MockupsSelectValue}
					onSelect={handleSelect}
					onNextAction={props.nextAction.onNextAction}
					idRef={props.idRef}
				/>
			default: {
				const errorMessage = "Unknown select Type in Select"
				Sentry.captureMessage(errorMessage)
				throw new Error(errorMessage)
			}
		}
	}

	const renderOptionList = () => {
		if (props.stepConfig.selectType === SelectType.colors || props.stepConfig.selectType === SelectType.fonts) {
			return <DraggableSelectOptions setUserCanSelect={setUserCanSelect}>
				{renderSelectType()}
			</DraggableSelectOptions>
		} else {
			return <SelectOptions twoColumnsMobile={
				props.stepConfig.selectType === SelectType.custom
				|| props.stepConfig.selectType === SelectType.images
			}>
				{renderSelectType()}
			</SelectOptions>
		}
	}

	const betaMessage = props.isBeta ? (
		<div className={Styles.beta_message}>
			<InfoIcon /> This content is AI-generated, it may contain errors.
		</div>
	) : null

	return (
		<div className={Styles.container}>
			<p className={Styles.related_action_title}>{props.relatedActionTitle}</p>
			<p className={Styles.step_title}>{props.stepConfig.title}</p>
			<div className={Styles.select_container}>

				<div className={Styles.select_type_container}>
					{renderOptionList()}
				</div>

				<div className={`${Styles.action_container} ${Styles.desktop}`}>
					{betaMessage}
					<ButtonIcon
						type="button"
						iconSVGComponent={props.isLastStep ? AirplaneIcon : ArrowRightIcon}
						isLoading={props.isLoading}
						onClick={props.nextAction.onNextAction}
						disabled={props.nextAction.isDisabled}
					/>
				</div>
				<div className={`${Styles.action_container} ${Styles.mobile}`}>
					{betaMessage}
					<Button
						type="button"
						icon={{
							SVGComponent: props.isLastStep ? AirplaneIcon : ArrowRightIcon,
							position: "right",
						}}
						onClick={props.nextAction.onNextAction}
						isLoading={props.isLoading}
						disabled={props.nextAction.isDisabled}
						customStyles={{
							variant: "primary",
							size: "large",
							fullWidth: true,
						}}
					>
						{props.isLastStep ? "Send" : "Next"}
					</Button>
				</div>
			</div>

		</div>
	)
}

export default Select
